import { atom } from "jotai";
import { State } from "./State";
//import { User } from "./UsersApi";
import { postBase } from "./BaseApi";

export type LoginResponse = {
  isFirstLogin: boolean;
};

type Form = {
  userId: string;
  password: string;
};

//UserはログインAPIの戻り値に利用しないため削除
//export const loginAtom = atom<State<User> | null>(null);

export const loginAtom = atom<State<LoginResponse> | null>(null);

export const login = () => {
  const url = "api/login";
  return postBase<LoginResponse, Form>(url, loginAtom);
};
