import classes from "./Serviceterm.module.scss";
import React, { CSSProperties, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import icon from "../../static/images/header_icon.png";

const Serviceterm = () => {
  
  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <img className={classes.img} src={icon} alt={icon} />
      </div>
      <div className={classes.body}>
      <section className={classes.serviceterm}>
      <h1 className={classes.title}>「みらいいコンシェル」利用規約</h1>
            <p className={classes.paragraph}>
            この「みらいいコンシェル」利用規約（以下「本規約」といいます。）は、株式会社イノビオット（以下「当社」といいます。）が運営する「みらいいコンシェル」（以下「本サービス」といいます。）を利用するサービス利用者（第1条で定義します。）に適用されます。サービス利用者は、本規約に同意の上、本サービスを利用します。
            </p>
          <h2 className={classes.heading}>第1条 用語の定義</h2>
            <p className={classes.paragraph}>
            本規約における以下の各号の用語の意味は、当該各号に定めるとおりとします。
            </p>
            <p className={classes.listItem}>(1)「サービス提供サイト」とは、本サービスが提供されるウェブサイトをいいます。</p>
            <p className={classes.listItem}>(2)「サービス利用契約」とは、本サービスの利用に関する契約をいいます。</p>
            <p className={classes.listItem}>(3)「サービス利用者」とは、当社とサービス利用契約を締結した者をいいます。</p>
            <p className={classes.listItem}>(4)「登録情報」とは、サービス利用者がサービス提供サイトに登録したサービス利用者に関する情報をいいます。</p>
          <h2 className={classes.heading}>第2条 本サービスの内容</h2>
            <p className={classes.listItem}>1. 本サービスは、おカネとの関わり方だけでなく、子どもとの関わり方も診断・改善できる「親子×おカネ」に特化したアプリです。
            </p>
            <p className={classes.listItem}>2. 当社は、自らの判断により、随時本サービスの内容を変更することができます。</p>
            <p className={classes.listItem}>3. サービス利用者は、当社による本サービスの内容の変更について異議を唱え、または損害賠償その他の請求を行うことはできません。</p>

          <h2 className={classes.heading}>第3条 本サービスの利用</h2>
            <p className={classes.listItem}>1. サービス利用者は、本規約に同意することにより、本規約の定めに従って本サービスを利用することができます。</p>
            <p className={classes.listItem}>2. サービス利用者が本サービスを利用するために必要な端末、通信機器、ソフトウェア、インターネットへの接続に関する契約等の準備は、サービス利用者が自己の費用と責任において準備しなければなりません。</p>
            <p className={classes.listItem}>3. 当社は、サービス利用者による本サービスの利用環境について一切関与せず、また一切の責任を負いません。</p>

            <h2 className={classes.heading}>第4条 禁止事項</h2>
              <p className={classes.paragraph}>
                サービス利用者は、本サービスに関し、以下の各号の行為を行ってはなりません。
              </p>
              <p className={classes.listItem}>(1) 法令または本規約に違反する行為</p>
              <p className={classes.listItem}>(2) 当社または第三者に不当に不利益または損害を生じさせる行為</p>
              <p className={classes.listItem}>(3) 本サービスの各機能の目的外利用</p>
              <p className={classes.listItem}>(4) 本サービスを通常利用する際に用いられる方法とは異なる方法（自動化、スクレイピングを含みます。）による本サービスの利用</p>
              <p className={classes.listItem}>(5) 当社または第三者の知的財産権侵害、プライバシーもしくは肖像権の侵害</p>
              <p className={classes.listItem}>(6) 当社または第三者の秘密情報の漏洩</p>
              <p className={classes.listItem}>(7) 本サービスで閲覧できる情報の第三者提供</p>
              <p className={classes.listItem}>(8) 本人の同意を得ない第三者の個人情報の提供</p>
              <p className={classes.listItem}>(9) 当社または第三者に対する誹謗中傷、名誉または信用の毀損</p>
              <p className={classes.listItem}>(10) 当社または第三者へのなりすまし</p>
              <p className={classes.listItem}>(11) 本サービスの提供に用いられるシステムに記録された情報の改ざんまたは消去</p>
              <p className={classes.listItem}>(12) 有害なコンピューター・プログラム等の送信、または当社もしくは第三者のリクエストに応じて送信可能な状態におくこと</p>
              <p className={classes.listItem}>(13) 当社による本サービスの提供その他の業務の妨害</p>
              <p className={classes.listItem}>(14) 第三者による本サービスの利用の妨害</p>
              <p className={classes.listItem}>(15) コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報の送信</p>
              <p className={classes.listItem}>(16) 本サービスの提供に用いられているサーバーに過度な負荷をかける行為</p>
              <p className={classes.listItem}>(17) 本サービスの提供に用いられるシステムに設定されたアクセス制御機能の解除または回避その他の不正アクセス</p>
              <p className={classes.listItem}>(18) 本サービスに用いられるソフトウェアの複製、修正、転載、改変、変更、リバースエンジニアリング、逆アセンブル、逆コンパイル、翻訳または解析</p>
              <p className={classes.listItem}>(19) 詐欺的な手段（いわゆるフィッシング及びこれに類する手段を含みます。）による当社または第三者からの情報の取得</p>
              <p className={classes.listItem}>(20) 当社に対する過度に長時間の架電、同様の問い合わせの過度の繰り返し、または義務や理由のない対応の強要</p>
              <p className={classes.listItem}>(21) 機器の販売、情報の提供、ハイパーリンクの設定等により上記各号のいずれかに該当する行為を助長する行為</p>
              <p className={classes.listItem}>(22) 上記各号のいずれかに該当するおそれがある行為</p>
              <p className={classes.listItem}>(23) 上記各号のいずれかを試みる行為</p>
              <p className={classes.listItem}>(24) その他当社が不適当と判断した行為</p>

            <h2 className={classes.heading}>第5条 個人情報の取扱い</h2>
              <p className={classes.paragraph}>
              サービス利用者は、当社が定めるプライバシーポリシーに従ってサービス利用者の個人情報を取り扱うことに同意します。
              </p>
    
            <h2 className={classes.heading}>第6条 統計情報の利用</h2>
              <p className={classes.paragraph}>
              サービス利用者は、当社が、サービス利用者の情報及びサービス利用者による本サービスの利用状況について、特定の個人を識別できないよう統計的に処理した上で自ら利用し、また第三者に提供することに同意します。
              </p>

            <h2 className={classes.heading}>第7条 本サービスに関する権利</h2>
              <p className={classes.paragraph}>
                本サービスに含まれる登録情報以外のコンテンツ及び情報等に関する知的財産権その他の権利は、当社または当社の指定する第三者に帰属しています。
              </p>

            <h2 className={classes.heading}>第8条 本サービスの提供の停止等</h2>
              <p className={classes.paragraph}>
                当社は、以下のいずれかの場合には、損害賠償その他の義務を負うことなく、一時的に本サービスの全部または一部の提供を停止できるものとします。
              </p>
              <p className={classes.listItem}>(1) 本サービスを提供するための設備の保守または点検等を行う場合</p>
              <p className={classes.listItem}>(2) 火災、停電等により本サービスの提供に支障が生じた場合</p>
              <p className={classes.listItem}>(3) 地震、噴火、洪水、津波等の天災により本サービスの提供に支障が生じた場合</p>
              <p className={classes.listItem}>(4) 戦争、動乱、暴動、騒乱、労働争議、感染症の蔓延、緊急事態宣言の発出等により本サービスの提供に支障が生じた場合</p>
              <p className={classes.listItem}>(5) 本サービスの運用上または技術上の理由により、当社が本サービスの提供を一時的に延期または中断する必要があると判断した場合</p>


              <h2 className={classes.heading}>第9条 利用制限等</h2>
                <p className={classes.paragraph}>
                  1.  当社は、サービス利用者が以下の各号のいずれかに該当すると判断した場合には、サービス利用者に事前に通知することなく、本サービスの利用の停止、本サービスの全部もしくは一部の提供の停止、またはサービス利用契約の解除等の措置（以下、本条において「利用制限措置」といいます。）を講じることができるものとします。
                </p>
                  <p className={classes.listItem}>(1) 当社がサービス利用者に対して発信した回答を求める連絡後1ヶ月以内に、当社が指定した方法による返信がない場合</p>
                  <p className={classes.listItem}>(2) 登録情報に含まれるサービス利用者の連絡先宛ての連絡がサービス利用者に到達せず、または受領拒否もしくは返送された場合</p>
                  <p className={classes.listItem}>(3) 本規約に違反し、またはそのおそれがある場合</p>
                  <p className={classes.listItem}>(4) 前各号の他、利用制限措置を講じる必要があると当社が判断した場合</p>
                <p className={classes.paragraph}>
                  2.  当社が第1項に基づき利用制限措置を講じたことにより、サービス利用者に損害が発生した場合であっても、当社は当該損害について一切責任を負いません。
                </p>

              <h2 className={classes.heading}>第10条 サービス利用者によるサービス利用契約の解約</h2>
              <p className={classes.paragraph}>
                サービス利用者は、サービス提供サイト上での登録解除手続その他の当社が別途指定する契約解除手続を行うことにより、いつでもサービス利用契約を終了させることができます。
              </p>

              <h2 className={classes.heading}>第11条 非保証</h2>
                <p className={classes.paragraph}>
                  1.  当社は、本サービスを通じてサービス利用者に提供される情報に関し、その完全性、正確性、特定の目的との適合性、有用性、安全性、確実性等について保証しません。
                </p>
                <p className={classes.paragraph}>
                  2.  当社は、本サービスが中断、中止、停止その他の障害が生じることなく継続的に提供され続けることを保証しません。
                </p>

              <h2 className={classes.heading}>第12条 免責</h2>
                <p className={classes.paragraph}>
                  1.  当社は、天災地変、戦争、暴動、内乱、延焼による火災、洪水、法令の改廃制定、停電、公権力の介入、ストライキその他の労働争議、輸送機関の事故、感染症の蔓延、緊急事態宣言の発令その他当社の責めに帰すべからざる事由より生じたサービス利用契約上の義務の不履行について、損害賠償責任その他一切の責任を負いません。
                </p>
                <p className={classes.paragraph}>
                  2.  サービス利用者が本サービスを利用したこと、または利用できなかったことを原因として生じるいかなる損害（付随的損害、特別損害、間接損害、逸失利益を含むがこれに限られません。）について、当社は、いかなる債務不履行責任、不法行為責任その他の法律上の責任も負わないものとします。
                </p>
                <p className={classes.paragraph}>
                  3.  本サービスを通じて、当社から提供される情報は当社が保有する技術、ノウハウ、統計データ等に基づくものにすぎず、サービス利用者の詳細な個別の事情を完全に考慮したものではなく、その情報に対して、当社は、いかなる債務不履行責任、不法行為責任その他の法律上の責任も負わないものとします。
                </p>
                <p className={classes.paragraph}>
                  4.  本規約の他の定めにかかわらず、当社は、当社の故意または重過失によりサービス利用者に損害を与えた場合には、その損害を賠償する責任を負うものとします。
                </p>
                <p className={classes.paragraph}>
                  5.  前項の場合を除き、本規約の免責規定が適用されない等の理由により、当社がサービス利用者に対して責任を負う場合、損害賠償の範囲は、現実かつ直接に発生した通常の損害（付随的損害、特別損害、間接損害、逸失利益及び弁護士費用を除きます。）の範囲内とし、かつ1万円を上限とします。
                </p>

              <h2 className={classes.heading}>第13条 連絡・通知</h2>
                <p className={classes.paragraph}>
                  1.  サービス利用者は、当社からサービス利用者への連絡及び通知等は、当社が指定する方法により行われることに同意します。
                </p>
                <p className={classes.paragraph}>
                  2.  サービス利用者は、当社が、登録情報に含まれる電子メールアドレスその他の連絡先に連絡または通知等を発した場合、当該連絡または通知等が通常到達すべき期間の経過をもって、当該連絡及び通知等はサービス利用者に到達したものとみなされることに同意します。
                </p>

              <h2 className={classes.heading}>第14条 反社会的勢力等の排除</h2>
                <p className={classes.paragraph}>
                  1.  サービス利用者及び当社は、現在、暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋、社会運動標ぼうゴロ・特殊知能暴力集団・暴力団員でなくなってから5年を経過していない者等、その他これらに準ずる者（以下、これらを「反社会的勢力等」といいます。）に該当しないこと、及び、次の各号の関係に該当しないことを表明し、かつ、将来にわたって該当しないことを確約します。
                </p>
                  <p className={classes.listItem}>(1) 反社会的勢力等によって、その経営を支配される関係</p>
                  <p className={classes.listItem}>(2) 反社会的勢力等が、その経営に実質的に関与している関係</p>
                  <p className={classes.listItem}>(3) 自己、自社もしくは第三者の不正の利益を図り、または第三者に損害を加える目的をもってする等、不当に反社会的勢力等を利用している関係</p>
                  <p className={classes.listItem}>(4) 反社会的勢力等に対して資金等を提供し、または便宜を供する等の関係</p>
                  <p className={classes.listItem}>(5) 役員等の反社会的勢力等との社会的に非難されるべき関係</p>
                <p className={classes.paragraph}>
                  2.  サービス利用者及び当社は、自ら、その役員等または第三者を利用して次の各号のいずれの行為も行わないことを誓約します。
                </p>
                  <p className={classes.listItem}>(1) 暴力的な要求行為</p>
                  <p className={classes.listItem}>(2) 法的な責任を超えた不当な要求行為</p>
                  <p className={classes.listItem}>(3) 取引に関して脅迫的な言動をし、または暴力を用いる行為</p>
                  <p className={classes.listItem}>(4) 風説を流布し、偽計もしくは威力を用いて相手方の信用を毀損し、または相手方の業務を妨害する行為</p>
                  <p className={classes.listItem}>(5) その他前各号に準ずる行為</p>
                <p className={classes.paragraph}>
                  3.  サービス利用者及び当社いずれかにおいて、前二項に違反した場合、相手方は、催告なしでサービス利用契約をただちに解除できるものとします。
                </p>
                <p className={classes.paragraph}>
                  4.  本条の規定によりサービス利用契約が解除された場合には、解除された当事者は、解除により生じる損害について、解除した当事者に対し一切の請求を行いません。
                </p>

              <h2 className={classes.heading}>第15条 サービス利用契約上の地位の移転等</h2>
                <p className={classes.paragraph}>
                  1.  サービス利用者は、サービス利用契約上の地位またはサービス利用契約に関して有する権利もしくは義務について、第三者に譲渡、承継、売買、名義変更、担保設定等をすることはできません。
                </p>
                <p className={classes.paragraph}>
                  2.  事業譲渡、会社分割、合併等の方法を問わず、当社が第三者に対して本サービスにかかる事業の譲渡または承継（以下「事業譲渡等」といいます。）をする場合には、当社は当該事業譲渡等に伴い、サービス利用契約上の地位、サービス利用契約に基づく権利及び義務並びに登録情報を取り扱う権限を、サービス利用者の別段の同意を得ることなく当該事業譲渡等の譲受人に譲渡または承継することができるものとします。
                </p>
              <h2 className={classes.heading}>第16条 サービス利用契約上の地位の移転等</h2>
                <p className={classes.paragraph}>
                  サービス利用契約が終了した場合であっても、サービス利用者は、サービス利用契約の終了前に発生した義務を免れません。
                </p>
              <h2 className={classes.heading}>第17条 本規約の変更</h2>
                <p className={classes.paragraph}>
                  1.  当社は、以下の各号の場合には、サービス利用者の事前の承諾を得ることなく、本規約を変更できるものとします。この場合、当社は、事前に変更後の利用規約をサービス利用者に通知します。
                </p>
                <p className={classes.listItem}>(1) 本規約の変更内容がサービス利用者の一般の利益に適合する場合</p>
                <p className={classes.listItem}>(2) 本規約の変更がサービス利用契約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性、その他変更にかかる事情に照らして合理的である場合</p>
                <p className={classes.paragraph}>
                  2.  当社は、前項に定める他、サービス利用者から本規約の変更について同意を得ることにより、本規約を変更できるものとします。なお、以下の各号の場合には、サービス利用者は、本規約の変更に同意したものとみなします。
                </p>
                <p className={classes.listItem}>(1) 当社が変更の1ヶ月以上前にサービス利用者に対して本規約の変更案及び本規約の変更日を通知し、本規約の変更日までにサービス利用者から当社に対し、書面により本規約の変更に対する反対の意思表示がなされなかった場合</p>
                <p className={classes.listItem}>(2) 当社がサービス利用者に対して本規約の変更案及び本規約の変更日を通知し、かつサービス利用者が当該変更日以降に本サービスを利用した場合</p>
              <h2 className={classes.heading}>第18条 無効または失効不能時の解釈</h2>
                <p className={classes.paragraph}>
                 本規約の条項の一部が無効または執行不能とされた場合であっても、無効または執行不能とされた部分以外の条項は継続して効力を有します。
                </p>
              <h2 className={classes.heading}>第19条 準拠法</h2>
                <p className={classes.paragraph}>
                  本規約は、日本法に準拠して解釈されます。
                </p>
              <h2 className={classes.heading}>第20条 合意管轄</h2>
                <p className={classes.paragraph}>
                本規約またはサービス利用契約に関するサービス利用者と当社との間の訴訟については、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
                </p>
              <h2 className={classes.heading}>第21条 存続規定</h2>
                <p className={classes.paragraph}>
                サービス利用契約の終了後も、第6条（統計情報の利用）、第12条（免責）、第13条（連絡・通知）、第15条（サービス利用契約上の地位の移転等）、第16条（義務の存続）、第18条（無効または失効不能時の解釈）、第19条（準拠法）、第20条（合意管轄）及び本条の各規定は有効に存続します。
                </p>
                <p className={classes.listItem}>2024年9月7日 制定</p>
      </section>
      </div>
    </div>
  )
};
export default Serviceterm;
