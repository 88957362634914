import React from "react";
import classes from "./DiagnosisNavigate.module.scss";
import { useNavigate } from "react-router-dom";
import diagnosisLogo from "../../static/images/diagnosisLogo.svg";

const DiagnosisNavigate = () => {
  const navigate = useNavigate();
  return (
    <div
      className={classes.diagnosisLogo}
      onClick={() => navigate("/diagnosis")}
    >
      <img src={diagnosisLogo} alt="diagnosisLogo" />
    </div>
  );
};

export default DiagnosisNavigate;
