import React, { ReactNode } from "react";
import classes from "./ModalTemplate.module.scss";
import { useAtom } from "jotai";
import { modalStateAtom } from "../../state/modal/ModalAtom";

interface ModalTemplateProps {
  children?: ReactNode;
  width: number;
  height: number;
}

const ModalTemplate = (props: ModalTemplateProps) => {
  const [modalState, setModalState] = useAtom(modalStateAtom);

  if (!modalState.isOpen) return null;
  return (
    <div className={classes.container}>
      <div
        className={classes.modal}
        style={{ width: props.width, height: props.height }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default ModalTemplate;
