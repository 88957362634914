export type Question = {
  questionNo: number;
  question: string;
  answerList: AnswerList[];
};

type AnswerList = {
  answerNo: number;
  answer: string;
};

export const questionList: Question[] = [
  {
    questionNo: 1,
    question: "お金の知識の中でも<br />特に必要だと感じるのはどれ？",
    answerList: [
      {
        answerNo: 1,
        answer: "節約",
      },
      {
        answerNo: 2,
        answer: "貯金",
      },
      {
        answerNo: 3,
        answer: "保険",
      },
      {
        answerNo: 4,
        answer: "投資",
      },
    ],
  },
  {
    questionNo: 2,
    question: "宝くじで100万円当たったら<br />どうする？",
    answerList: [
      {
        answerNo: 1,
        answer: "パーッと使っちゃう",
      },
      {
        answerNo: 2,
        answer: "とりあえず貯金する",
      },
      {
        answerNo: 3,
        answer: "貯金しつつ運用もする",
      },
      {
        answerNo: 4,
        answer: "将来のため全て運用する",
      },
    ],
  },
  {
    questionNo: 3,
    question: "将来のための<br />貯金はできている？",
    answerList: [
      {
        answerNo: 1,
        answer: "貯金できている",
      },
      {
        answerNo: 2,
        answer: "まあまあ貯金できている",
      },
      {
        answerNo: 3,
        answer: "あまり貯金できていない",
      },
      {
        answerNo: 4,
        answer: "貯金できていない",
      },
    ],
  },
  {
    questionNo: 4,
    question: "家計簿は<br />誰がつけている？",
    answerList: [
      {
        answerNo: 1,
        answer: "自分",
      },
      {
        answerNo: 2,
        answer: "パートナー",
      },
      {
        answerNo: 3,
        answer: "他の人",
      },
      {
        answerNo: 4,
        answer: "家計簿はつけていない",
      },
    ],
  },
  {
    questionNo: 5,
    question: "買いたいけど予算オーバー！<br />どうする？",
    answerList: [
      {
        answerNo: 1,
        answer: "速攻で買う",
      },
      {
        answerNo: 2,
        answer: "その場でパッと調べてから買う",
      },
      {
        answerNo: 3,
        answer: "じっくり比較検討してから買う",
      },
      {
        answerNo: 4,
        answer: "1ヶ月以上経っても欲しければ買う",
      },
    ],
  },
  {
    questionNo: 6,
    question: "加入中の保険や投資の内容は<br />理解できている？",
    answerList: [
      {
        answerNo: 1,
        answer: "理解できている",
      },
      {
        answerNo: 2,
        answer: "なんとなく理解できている",
      },
      {
        answerNo: 3,
        answer: "あまり理解できていない",
      },
      {
        answerNo: 4,
        answer: "そもそも金融商品に手を付けていない",
      },
    ],
  },
  {
    questionNo: 7,
    question: "理想とする<br />リスクとリターンのバランスは？",
    answerList: [
      {
        answerNo: 1,
        answer: "ハイリスク・ハイリターン",
      },
      {
        answerNo: 2,
        answer: "ミドルリスク・ミドルリターン",
      },
      {
        answerNo: 3,
        answer: "ローリスク・ローリターン",
      },
      {
        answerNo: 4,
        answer: "ノーリスク・ノーリターン",
      },
    ],
  },
  {
    questionNo: 8,
    question: "投資をやってみたいと<br />思ったことがある？",
    answerList: [
      {
        answerNo: 1,
        answer: "すでに始めている",
      },
      {
        answerNo: 2,
        answer: "やりたいと思っている",
      },
      {
        answerNo: 3,
        answer: "興味はあるけどちょっと怖い",
      },
      {
        answerNo: 4,
        answer: "投資には興味がない",
      },
    ],
  },
  {
    questionNo: 9,
    question: "お金について<br />子供と話すことはある？",
    answerList: [
      {
        answerNo: 1,
        answer: "週1回くらい",
      },
      {
        answerNo: 2,
        answer: "月1回くらい",
      },
      {
        answerNo: 3,
        answer: "年1回くらい",
      },
      {
        answerNo: 4,
        answer: "あまり話せていない",
      },
    ],
  },
  {
    questionNo: 10,
    question: "将来の夢や目標について<br />子供と話すことはある？",
    answerList: [
      {
        answerNo: 1,
        answer: "週1回くらい",
      },
      {
        answerNo: 2,
        answer: "月1回くらい",
      },
      {
        answerNo: 3,
        answer: "年1回くらい",
      },
      {
        answerNo: 4,
        answer: "あまり話せていない",
      },
    ],
  },
  {
    questionNo: 11,
    question: "子供のお小遣い<br />どうしてる？",
    answerList: [
      {
        answerNo: 1,
        answer: "定額制",
      },
      {
        answerNo: 2,
        answer: "報酬制",
      },
      {
        answerNo: 3,
        answer: "都度払い",
      },
      {
        answerNo: 4,
        answer: "その他",
      },
    ],
  },
  {
    questionNo: 12,
    question: "お金の使い方のルールを<br />決めている？",
    answerList: [
      {
        answerNo: 1,
        answer: "家族全員ルールを決めている",
      },
      {
        answerNo: 2,
        answer: "親だけルールを決めている",
      },
      {
        answerNo: 3,
        answer: "子供だけルールを決めている",
      },
      {
        answerNo: 4,
        answer: "特にルールは決めていない",
      },
    ],
  },
  {
    questionNo: 13,
    question: "家族とお金の話をする時は<br />どんな感じ？",
    answerList: [
      {
        answerNo: 1,
        answer: "いつも通り",
      },
      {
        answerNo: 2,
        answer: "ワクワクする",
      },
      {
        answerNo: 3,
        answer: "ドキドキする",
      },
      {
        answerNo: 4,
        answer: "イライラする",
      },
    ],
  },
  {
    questionNo: 14,
    question: "子供にお金を渡す<br />目的は？",
    answerList: [
      {
        answerNo: 1,
        answer: "頑張ったご褒美をあげたい",
      },
      {
        answerNo: 2,
        answer: "必要なものは自分で買ってほしい",
      },
      {
        answerNo: 3,
        answer: "貯金の習慣を身につけてほしい",
      },
      {
        answerNo: 4,
        answer: "特に目的はない",
      },
    ],
  },
  {
    questionNo: 15,
    question: "子供から高額商品のおねだり！<br />買う／買わないは誰が決める？",
    answerList: [
      {
        answerNo: 1,
        answer: "自分ひとりで決める",
      },
      {
        answerNo: 2,
        answer: "大人だけで話し合って決める",
      },
      {
        answerNo: 3,
        answer: "自分と子供で話し合って決める",
      },
      {
        answerNo: 4,
        answer: "家族全員で話し合って決める",
      },
    ],
  },
];
