import React, { useEffect, useState } from "react";
import classes from "./Lifeplan.module.scss";
import Header from "../../molecules/header/Header";
import PageTitle from "../../atoms/page-title/PageTitle";
import lockImage from "../../static/images/lock-icon.svg";
import TeacherComment from "../../molecules/teacher-commnet/TeacherComment";
import teacherThink from "../../static/images/teacher-think.svg";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";
import { fetchUsersEducationFee } from "../../api/UsersEducationFeeApi";
import { addComma, round } from "../../common/utility/number.util";
import BannerCounselling from "../../molecules/banner-counselling/BannerCounselling";
import LifeplanChart, {
  ChartData,
} from "../../organism/lifeplan-chart/LifeplanChart";
import {
  ChartSetType,
  fetchUsersEducationChart,
} from "../../api/UsersEducationChartApi";
import { fetchUser } from "../../api/UsersApi";
import { RankCategory } from "../../api/UsersActionApi";
import DiagnosisNavigate from "../../molecules/diagnosis-navigate/DiagnosisNavigate";
import {
  fetchUsersRevenueExpenseChart,
  fetchUsersRevenueExpenseChartAtom,
} from "../../api/UsersRevenueExpenseChartApi";
import { fetchUsersRetirementChart } from "../../api/UsersRetirementChartApi";
import { fetchUsersLifeplanAdviceChart } from "../../api/UsersLifeplanAdviceApi";
import { fetchUsersRetirementFee } from "../../api/UsersRetirementFeeApi";
import { fetchUsersLifeplanChart } from "../../api/UsersLifeplanChart";
import normalLifeplanIcon from "../../static/images/lifeplan-summary/normal.svg";
import notSoGoodLifeplanIcon from "../../static/images/lifeplan-summary/not-so-good.svg";
import badLifeplanIcon from "../../static/images/lifeplan-summary/bad.svg";
import { fetchUsersLifeplanAchievement } from "../../api/UsersLifeplanAchievement";
import lifeplanAchievementImage from "../../static/images/lifeplan-achievement-icon.png";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { errorAtom } from "../error-handler/ErrorHandler";

const Lifeplan = () => {
  const [chartData, setChartData] = useState<ChartData[]>();
  const [showAge, setShowAge] = useState<number>();
  const [currentAge, setCurrentAge] = useState<number>();
  const [currentRank, setCurrentRank] = useState<RankCategory>("bronze");
  const [averageAnnualExpense, setAverageAnnualExpense] = useState<string>();
  const [averageAnnualRevenue, setAverageAnnualRevenue] = useState<string>();
  const [annualSaving, setAnnualSaving] = useState<string>();
  const [annualCashflow, setAnnualCashflow] = useState<string>();
  const [totalAsset, setTotalAsset] = useState<string>();
  const [hasChildren, setHasChildren] = useState<boolean>();

  const navigate = useNavigate();
  const [errorState, setErrorState] = useAtom(errorAtom);
  const [errorHandleState, setErrorHandleState] = useState<boolean>(false);

  const usersEducationFeeApi = fetchUsersEducationFee();
  const usersRetirementFeeApi = fetchUsersRetirementFee();
  const usersEducationChartApi = fetchUsersEducationChart();
  const usersRevenueExpenseChartApi = fetchUsersRevenueExpenseChart();
  const usersRetirementChartApi = fetchUsersRetirementChart();
  const usersLifeplanAdviceApi = fetchUsersLifeplanAdviceChart();
  const usersLifeplanChartApi = fetchUsersLifeplanChart();
  const usersLifeplanAchievementApi = fetchUsersLifeplanAchievement();
  const usersApi = fetchUser();
  useEffect(() => {
    usersApi.fetch();
    usersLifeplanAdviceApi.fetch();
  }, []);

  useEffect(() => {
    // stateの中身がnullになった時点でキャッシュの初期化完了とする
    if (usersApi.state?.data == null) {
      setErrorHandleState(true);
      return;
    }

    // APIリクエストのキャッシュ残っちゃうのでフラグで判定
    if (!errorHandleState) {
      return;
    }

    setErrorHandleState(false);
    const currentRank = usersApi.state.data[0].currentRank;
    const hasChildren = usersApi.state.data[0].isChildren;
    setCurrentRank(currentRank);
    setHasChildren(hasChildren);

    if (currentRank === "bronze" && !hasChildren) {
      setErrorState(["ライフプランはまだ解放されていません。"]);
      navigate("/action");
    }
  }, [errorHandleState, usersApi.state?.data]);

  useEffect(() => {
    usersEducationFeeApi.fetch();
    usersEducationChartApi.fetch();

    if (currentRank === "bronze") {
      return;
    }

    usersRevenueExpenseChartApi.fetch();

    if (currentRank === "silver") {
      return;
    }

    usersRetirementFeeApi.fetch();
    usersRetirementChartApi.fetch();

    if (currentRank === "gold") {
      return;
    }

    usersLifeplanChartApi.fetch();
    usersLifeplanAchievementApi.fetch();
  }, [currentRank]);

  useEffect(() => {
    const education = usersEducationChartApi.state?.data?.[0];
    const revenueExpense = usersRevenueExpenseChartApi?.state?.data?.[0];
    const retirement = usersRetirementChartApi?.state?.data?.[0];

    const chartData = usersEducationChartApi?.state?.data?.[0].chartSet.map(
      (v, index) => {
        return {
          decade: education?.chartSet[index].decade ?? 0,
          education: education?.chartSet[index].expense ?? 0,
          expense:
            (revenueExpense?.chartSet[index].expense ?? 0) +
            (retirement?.chartSet[index].expense ?? 0),
          revenue:
            (revenueExpense?.chartSet[index].revenue ?? 0) +
            (retirement?.chartSet[index].revenue ?? 0),
          cash:
            usersLifeplanChartApi?.state?.data?.[0].chartSet[index].totalCash ??
            0,
          asset:
            usersLifeplanChartApi?.state?.data?.[0].chartSet[index]
              .totalAsset ?? 0,
        };
      }
    );
    setChartData(chartData);
    setCurrentAge(usersEducationChartApi?.state?.data?.[0].currentAge ?? 0);
    setShowAge(
      round(usersEducationChartApi?.state?.data?.[0].currentAge, "floor", -1) ??
        0
    );
  }, [
    usersEducationChartApi.state?.status,
    usersRevenueExpenseChartApi.state?.status,
    usersRetirementChartApi.state?.status,
    usersLifeplanChartApi.state?.status,
  ]);

  useEffect(() => {
    const education = usersEducationChartApi.state?.data?.[0];
    const revenueExpense = usersRevenueExpenseChartApi.state?.data?.[0];
    const retirement = usersRetirementChartApi.state?.data?.[0];
    const lifeplan = usersLifeplanChartApi.state?.data?.[0];

    if (currentAge == null || showAge == null) {
      return;
    }

    const educationRevenue =
      education?.chartSet.find((d) => d.decade === showAge)?.revenue ?? 0;
    const educationExpense =
      education?.chartSet.find((d) => d.decade === showAge)?.expense ?? 0;
    const revenueExpenseRevenue =
      currentRank !== "bronze"
        ? revenueExpense?.chartSet.find((d) => d.decade === showAge)?.revenue ??
          0
        : 0;
    const revenueExpenseExpense =
      currentRank !== "bronze"
        ? revenueExpense?.chartSet.find((d) => d.decade === showAge)?.expense ??
          0
        : 0;
    const retirementRevenue =
      currentRank !== "bronze"
        ? retirement?.chartSet.find((d) => d.decade === showAge)?.revenue ?? 0
        : 0;
    const retirementExpense =
      currentRank !== "bronze"
        ? retirement?.chartSet.find((d) => d.decade === showAge)?.expense ?? 0
        : 0;
    const annualSaving =
      currentRank === "platinum"
        ? lifeplan?.chartSet.find((d) => d.decade === showAge)?.annualSaving ??
          0
        : 0;

    const annualCashflow =
      currentRank === "platinum"
        ? lifeplan?.chartSet.find((d) => d.decade === showAge)
            ?.annualCashflow ?? 0
        : 0;

    const totalAsset =
      currentRank === "platinum"
        ? lifeplan?.chartSet.find((d) => d.decade === showAge)?.totalAsset ?? 0
        : 0;

    const sumRevenue =
      educationRevenue + revenueExpenseRevenue + retirementRevenue;
    const sumExpense =
      educationExpense + revenueExpenseExpense + retirementExpense;

    const divide = (() => {
      const tmp = currentAge - showAge;

      // currentAge = 32
      // showAge = 30
      // 8で割りたい
      if (0 < tmp && tmp < 10) {
        return 10 - tmp;
      }

      return 10;
    })();

    setAverageAnnualExpense(
      `${addComma(round(sumExpense / divide / 10000, "ceil", 0) ?? 0)}万円`
    );
    setAverageAnnualRevenue(
      `${addComma(round(sumRevenue / divide / 10000, "ceil", 0) ?? 0)}万円`
    );

    setAnnualSaving(
      `${addComma(round(annualSaving / divide / 10000, "ceil", 0) ?? 0)}万円`
    );
    setAnnualCashflow(
      `${addComma(round(annualCashflow / divide / 10000, "ceil", 0) ?? 0)}万円`
    );
    setTotalAsset(
      `${addComma(round(totalAsset / 1 / 10000, "ceil", 0) ?? 0)}万円`
    );
  }, [
    showAge,
    currentAge,
    usersEducationChartApi.state?.status,
    usersRevenueExpenseChartApi.state?.status,
    usersRetirementChartApi.state?.status,
    usersLifeplanChartApi.state?.status,
  ]);

  const getLifeplanScore = (): "normal" | "notSoGood" | "bad" => {
    if (usersLifeplanChartApi.state?.data == null) {
      return "bad";
    }

    const flowEvaluation = usersLifeplanChartApi.state?.data[0].flowEvaluation;
    const stockEvaluation =
      usersLifeplanChartApi.state?.data[0].stockEvaluation;

    if (flowEvaluation && stockEvaluation) {
      return "normal";
    }

    if (flowEvaluation || stockEvaluation) {
      return "notSoGood";
    }

    return "bad";
  };

  const lifeplanScoreClassMap = {
    normal: classes.normal,
    notSoGood: classes.notSoGood,
    bad: classes.bad,
  };

  const lifeplanScoreIconMap = {
    normal: normalLifeplanIcon,
    notSoGood: notSoGoodLifeplanIcon,
    bad: badLifeplanIcon,
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Header />
      </div>
      <PageTitle title="ライフプラン" rank={currentRank} />
      <div className={classes.body}>
        {["platinum"].includes(currentRank) ? (
          <div className={classes.lifeplanScoreContainer}>
            <div className={classes.outerCard}>
              <div className={classes.title}>ライフプランの見通し</div>
              <div className={`${classes.card} ${classes.yellow}`}>
                <div className={classes.cardBody}>
                  <div className={classes.block}>
                    <img
                      src={lifeplanScoreIconMap[getLifeplanScore()]}
                      alt={lifeplanScoreIconMap[getLifeplanScore()]}
                    />
                  </div>
                  <div className={classes.block}>
                    <span
                      className={`${classes.value} ${lifeplanScoreClassMap[getLifeplanScore()]}`}
                    >
                      {addComma(
                        round(
                          (usersLifeplanChartApi.state?.data?.[0]
                            .lastTotalAsset ?? 0) / 10000,
                          "ceil",
                          0
                        ) ?? 0
                      )}
                    </span>
                    <span
                      className={`${classes.unit} ${lifeplanScoreClassMap[getLifeplanScore()]}`}
                    >
                      万円
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.lockContainer}>
            <div className={`${classes.card} ${classes.yellow}`}>
              <div className={classes.title}>ライフプランの見通し</div>
              <img className={classes.icon} src={lockImage} alt={lockImage} />
              <div className={classes.caption}>プラチナランクで解放</div>
            </div>
          </div>
        )}
        {hasChildren && (
          <div className={classes.educationFinanceContainer}>
            <div className={classes.outerCard}>
              <div className={classes.title}>教育資金</div>
              <div className={classes.card}>
                <div className={classes.cardTitle}>
                  今後の教育に必要なお金の総額
                </div>
                <div className={classes.cardBody}>
                  <div className={classes.block}>
                    <span className={classes.value}>
                      {addComma(
                        round(
                          (usersEducationFeeApi.state?.data?.[0]
                            .totalTuitionFee ?? 0) / 10000,
                          "ceil",
                          0
                        ) ?? 0
                      )}
                    </span>
                    <span className={classes.unit}>万円</span>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.cardTitle}>
                  子ども全員が大学入学するまでの積立額
                </div>
                <div className={classes.cardBody}>
                  <div className={classes.block}>
                    <span className={classes.label}>月額</span>
                    <span className={classes.value}>
                      {addComma(
                        round(
                          (usersEducationFeeApi.state?.data?.[0]
                            .totalTuitionFeeMonthlyBased ?? 0) / 10000,
                          "ceil",
                          0
                        ) ?? 0
                      )}
                    </span>
                    <span className={classes.unit}>万円</span>
                  </div>
                  <div className={classes.block}>
                    <span className={classes.label}>年間</span>
                    <span className={classes.value}>
                      {addComma(
                        round(
                          (usersEducationFeeApi.state?.data?.[0]
                            .totalTuitionFeeYearlyBased ?? 0) / 10000,
                          "ceil",
                          0
                        ) ?? 0
                      )}
                    </span>
                    <span className={classes.unit}>万円</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {["bronze", "silver"].includes(currentRank) ? (
          <div className={classes.lockContainer}>
            <div className={`${classes.card} ${classes.blue}`}>
              <div className={classes.title}>老後資金</div>
              <img className={classes.icon} src={lockImage} alt={lockImage} />
              <div className={classes.caption}>ゴールドランクで解放</div>
            </div>
          </div>
        ) : (
          <div className={classes.retirementFinanceContainer}>
            <div className={classes.outerCard}>
              <div className={classes.title}>老後資金</div>
              <div className={classes.card}>
                <div className={classes.cardTitle}>老後に必要なお金の総額</div>
                <div className={classes.cardBody}>
                  <div className={classes.block}>
                    <span className={classes.value}>
                      {addComma(
                        round(
                          (usersRetirementFeeApi.state?.data?.[0]
                            .totalRetirementFee ?? 0) / 10000,
                          "ceil",
                          0
                        ) ?? 0
                      )}
                    </span>
                    <span className={classes.unit}>万円</span>
                  </div>
                </div>
              </div>
              <div className={classes.card}>
                <div className={classes.cardTitle}>
                  あなたが老後を迎えるまでの積立額
                </div>
                <div className={classes.cardBody}>
                  <div className={classes.block}>
                    <span className={classes.label}>月額</span>
                    <span className={classes.value}>
                      {addComma(
                        round(
                          (usersRetirementFeeApi.state?.data?.[0]
                            .totalRetirementMonthlyBased ?? 0) / 10000,
                          "ceil",
                          0
                        ) ?? 0
                      )}
                    </span>
                    <span className={classes.unit}>万円</span>
                  </div>
                  <div className={classes.block}>
                    <span className={classes.label}>年間</span>
                    <span className={classes.value}>
                      {addComma(
                        round(
                          (usersRetirementFeeApi.state?.data?.[0]
                            .totalRetirementYearlyBased ?? 0) / 10000,
                          "ceil",
                          0
                        ) ?? 0
                      )}
                    </span>
                    <span className={classes.unit}>万円</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/*
        <div className={classes.teacherComment}>
          <TeacherComment
            image={teacherThink}
            comment={[
              "教育資金が少し不安な状況ですね教育資金について学べるイベントがありますのでご検討ください",
            ]}
          />
        </div>
          */}
        <div className={classes.expenseContainer}>
          <div className={classes.header}>
            <div className={classes.title}>
              {currentRank === "bronze"
                ? "支出の推移(教育費のみ)"
                : "収入・支出の推移"}
            </div>
            {currentRank === "bronze" ? (
              <div className={`${classes.caption} ${classes.lockText}`}>
                収入・支出の推移は次のランクで解放
              </div>
            ) : (
              <div className={`${classes.caption}`}></div>
            )}
          </div>
          <div className={classes.chart}>
            <LifeplanChart
              chartData={chartData}
              currentAge={showAge}
              currentRank={currentRank}
              hasChildren={hasChildren}
              clickX={(p) => setShowAge(p.value)}
            />
          </div>
          <div className={classes.expenseSummaryContainer}>
            <div className={classes.header}>あなたが{showAge}代のとき</div>
            {currentRank === "bronze" && (
              <>
                <div className={classes.line}>
                  <div className={classes.lockText}>平均年間収入</div>
                  <div className={classes.value}>次のランクで解放</div>
                </div>
                <div className={classes.line}>
                  <div className={classes.text}>平均年間支出(教育費のみ)</div>
                  <div className={classes.value}>{averageAnnualExpense}</div>
                </div>
                <div className={classes.line}>
                  <div className={classes.lockText}>平均年間貯蓄</div>
                  <div className={classes.value}>プラチナランクで解放</div>
                </div>
                <div className={classes.line}>
                  <div className={classes.lockText}>年間キャッシュフロー</div>
                  <div className={classes.value}>プラチナランクで解放</div>
                </div>
                <div className={classes.line}>
                  <div className={classes.lockText}>総資産（年末時点）</div>
                  <div className={classes.value}>プラチナランクで解放</div>
                </div>
              </>
            )}
            {currentRank === "silver" && (
              <>
                <div className={classes.line}>
                  <div className={classes.text}>平均年間収入</div>
                  <div className={classes.value}>{averageAnnualRevenue}</div>
                </div>
                <div className={classes.line}>
                  <div className={classes.text}>平均年間支出</div>
                  <div className={classes.value}>{averageAnnualExpense}</div>
                </div>
                <div className={classes.line}>
                  <div className={classes.lockText}>平均年間貯蓄</div>
                  <div className={classes.value}>プラチナランクで解放</div>
                </div>
                <div className={classes.line}>
                  <div className={classes.lockText}>年間キャッシュフロー</div>
                  <div className={classes.value}>プラチナランクで解放</div>
                </div>
                <div className={classes.line}>
                  <div className={classes.lockText}>総資産（年末時点）</div>
                  <div className={classes.value}>プラチナランクで解放</div>
                </div>
              </>
            )}
            {currentRank === "gold" && (
              <>
                <div className={classes.line}>
                  <div className={classes.text}>平均年間収入</div>
                  <div className={classes.value}>{averageAnnualRevenue}</div>
                </div>
                <div className={classes.line}>
                  <div className={classes.text}>平均年間支出</div>
                  <div className={classes.value}>{averageAnnualExpense}</div>
                </div>
                <div className={classes.line}>
                  <div className={classes.lockText}>平均年間貯蓄</div>
                  <div className={classes.value}>次のランクで解放</div>
                </div>
                <div className={classes.line}>
                  <div className={classes.lockText}>年間キャッシュフロー</div>
                  <div className={classes.value}>次のランクで解放</div>
                </div>
                <div className={classes.line}>
                  <div className={classes.lockText}>総資産（年末時点）</div>
                  <div className={classes.value}>次のランクで解放</div>
                </div>
              </>
            )}
            {currentRank === "platinum" && (
              <>
                <div className={classes.line}>
                  <div className={classes.text}>平均年間収入</div>
                  <div className={classes.value}>{averageAnnualRevenue}</div>
                </div>
                <div className={classes.line}>
                  <div className={classes.text}>平均年間支出</div>
                  <div className={classes.value}>{averageAnnualExpense}</div>
                </div>
                <div className={classes.line}>
                  <div className={classes.text}>平均年間貯蓄</div>
                  <div className={classes.value}>{annualSaving}</div>
                </div>
                <div className={classes.line}>
                  <div className={classes.text}>年間キャッシュフロー</div>
                  <div className={classes.value}>{annualCashflow}</div>
                </div>
                <div className={classes.line}>
                  <div className={classes.text}>総資産（年末時点）</div>
                  <div className={classes.value}>{totalAsset}</div>
                </div>
              </>
            )}
          </div>
          <div className={classes.advice}>
            {usersLifeplanAdviceApi.state?.data?.[0].financeAdvice
              .split("\n")
              .map((text, index) => {
                return (
                  <div className={classes.text} key={`advice-${index}`}>
                    {text}
                  </div>
                );
              })}
          </div>
        </div>
        {usersApi.state?.data?.[0].diagnosisType === "no_diagnosis" && (
          <div className={classes.diagnosisContainer}>
            <DiagnosisNavigate />
          </div>
        )}
        {currentRank === "platinum" ? (
          <div
            className={`${classes.lifeplanAchievementContainer} ${classes.blue}`}
          >
            <div className={classes.title}>ライフプラン実現のポイント</div>
            <img
              className={classes.lifeplanAchievementImage}
              src={lifeplanAchievementImage}
              alt={lifeplanAchievementImage}
            />
            <div className={classes.advice}>
              {usersLifeplanAchievementApi.state?.data?.[0].lifeplanAdvice
                .split("\n")
                .map((text, index) => {
                  return (
                    <div className={classes.text} key={`advice-${index}`}>
                      {text}
                    </div>
                  );
                })}
            </div>
          </div>
        ) : (
          <div className={classes.lockContainer}>
            <div className={`${classes.card} ${classes.blue}`}>
              <div className={classes.title}>ライフプラン実現のポイント</div>
              <img className={classes.icon} src={lockImage} alt={lockImage} />
              <div className={classes.caption}>プラチナランクで解放</div>
            </div>
          </div>
        )}
        {/*
        <div className={classes.bannerCounselling}>
          <BannerCounselling />
        </div>
        */}
        <div className={classes.breadCrumb}>
          <BreadCrumb data={["みらいいコンシェル", "ライフプラン"]} />
        </div>
      </div>
    </div>
  );
};

export default Lifeplan;
