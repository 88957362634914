export const addComma = (value?: number) => {
  return value != null ? value.toLocaleString() : "";
};

export const formatSign = (value?: number, withPercent?: true) => {
  const suffix = withPercent ? "%" : "";

  if (value == null) {
    return "";
  }

  if (value === 0) {
    return `±${value}${suffix}`;
  }

  if (value > 0) {
    return `+${value}${suffix}`;
  }

  return `${value}${suffix}`;
};

export const toNumber = (value: any) => {
  if (value == null || value === "") {
    return null;
  }
  return Number(value);
};

/**
 *
 * @param value
 * @param type round = 四捨五入、ceil = 切り上げ、 floor = 切り捨て
 * @param digit 少数第何位でやるか(1 -> 整数, 2 -> 少数第二位切り上げ)
 * @returns 数値としておかしい場合はnullが返却
 */
export const round = (
  value: any,
  type: "round" | "ceil" | "floor",
  digit: number
) => {
  const v = toNumber(value);

  if (v == null || isNaN(v)) {
    return null;
  }

  const d = Math.pow(10, digit);
  const tmp = v * d;

  if (type === "round") {
    return Math.round(tmp) / d;
  }

  if (type === "ceil") {
    return Math.ceil(tmp) / d;
  }
  return Math.floor(tmp) / d;
};
