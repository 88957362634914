import { atom } from "jotai";
import { State } from "./State";
import { deleteBase, fetchBase, postBase, putBase } from "./BaseApi";

export type UsersGoal = {
  goalId: number;
  userId: string;
  createDate: Date;
  updateDate: Date;
  goalContent: string;
};

export type PostUsersGoalParams = {
  goalContent: string;
};

export type PutUsersGoalParams = PostUsersGoalParams & {
  goalId: number;
};

export type DeleteUsersGoalParams = {
  goalId: number;
};

const uri = "api/users/goal";

export const fetchUsersGoalAtom = atom<State<UsersGoal> | null>(null);
export const fetchUsersGoal = () => {
  return fetchBase<UsersGoal>(uri, fetchUsersGoalAtom);
};

export const postUsersGoalAtom = atom<State<null> | null>(null);
export const postUsersGoal = () => {
  return postBase<null, PostUsersGoalParams>(uri, postUsersGoalAtom);
};

export const putUsersGoalAtom = atom<State<null> | null>(null);
export const putUsersGoal = () => {
  return putBase<null, PutUsersGoalParams>(uri, putUsersGoalAtom);
};

export const deleteUsersGoalAtom = atom<State<null> | null>(null);
export const deleteUsersGoal = () => {
  return deleteBase<null, DeleteUsersGoalParams>(uri, deleteUsersGoalAtom);
};
