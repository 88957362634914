import React, { CSSProperties, useEffect, useState } from "react";
import classes from "./ActionDetail.module.scss";
import { Action, fetchAction, ActionInputType } from "../../api/ActionsApi";
import Header from "../../molecules/header/Header";
import {
  AcquiredBadgeType,
  RankCategory,
  fetchUsersAction,
  postUsersAction,
} from "../../api/UsersActionApi";
import bronze from "../../static/images/BadgeBronze.svg";
import silver from "../../static/images/BadgeSilver.svg";
import gold from "../../static/images/BadgeGold.svg";
import green from "../../static/images/BadgeGreenEdit.svg";
import rainbow from "../../static/images/BadgeRainbowEdit.svg";
import yellow from "../../static/images/BadgeYellowEdit.svg";
import gray from "../../static/images/BadgeGrayEdit.svg";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { toNumber } from "../../common/utility/number.util";
import TeacherComment from "../../molecules/teacher-commnet/TeacherComment";
import teacherImage from "../../static/images/teacher-normal.svg";
import arrowImage from "../../static/images/arrow-bottom-green.svg";
import Quiz from "../../molecules/quiz/Quiz";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";
import TextForm, { TextFormResult } from "../../atoms/text-form/TextForm";
import ActionInput from "../../molecules/action-input/ActionInput";
import BadgeResult from "../../organism/badge-result/BadgeResult";
import { useLoading } from "../loading/Loading";
import whiteHeart from "../../static/images/WhiteHeart.svg";
import yellowHeart from "../../static/images/YellowHeart.svg";
import useDocumentLoadCompleted from "../../common/hooks/DocumentLoadComplete";
import { postBookmark } from "../../api/UsersBookmark";
import { useAtom } from "jotai";
import {
  favoriteModalStateAtom,
  modalStateAtom,
} from "../../state/modal/ModalAtom";
import FavoriteModal from "../../organism/favorite-modal/FavoriteModal";

const ActionDetail = () => {
  const navigate = useNavigate();
  const loading = useLoading();
  const [searchParams] = useSearchParams();
  const actionId = toNumber(searchParams.get("actionId"));
  const actionType = searchParams.get("actionType");
  const fetchActionApi = fetchAction();
  const postUsersActionApi = postUsersAction();
  const fetchUsersActionApi = fetchUsersAction();
  const postUsersBookmarkApi = postBookmark();
  const [actionResponse, setActionResponse] = useState<Action>();
  const [rankCategory, setRankCategory] = useState<RankCategory>();
  const [acquiredBadgeType, setAcquiredBadgeType] =
    useState<AcquiredBadgeType>();
  const [buttonClass, setButtonClass] = useState<string>(
    `${classes.button} ${classes.disabled}`
  );
  const [isInterest, setIsInterest] = useState<boolean>();
  const [modalState, setModalState] = useAtom(favoriteModalStateAtom);

  const [quizParams, setQuizParams] = useState<
    { quizNumber: number; isCorrect: boolean }[]
  >([]);

  const [inputParams, setInputParams] = useState<
    (ActionInputType & { itemValue: string; status: boolean })[]
  >([]);

  const [showResult, setShowResult] = useState<"hide" | "badge" | "rankup">(
    "hide"
  );

  useEffect(() => {
    if (actionId == null || actionType == null) {
      return;
    }

    loading.show();
    fetchActionApi.fetch({ actionId: actionId });
    fetchUsersActionApi.fetch({ actionType: actionType });
    setShowResult("hide");

    //追加
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      fetchActionApi.state?.data == null ||
      fetchUsersActionApi.state?.data == null ||
      actionId == null
    ) {
      return;
    }

    setActionResponse(fetchActionApi.state.data[0]);
    const target = fetchUsersActionApi.state.data[0].actionList.find(
      (a) => a.actionId === actionId
    );

    setRankCategory(target?.rankCategory);
    setAcquiredBadgeType(target?.acquiredBadgeType);
    setIsInterest(target?.isInterest);

    setInputParams(
      fetchActionApi.state.data[0].inputList
        .sort((a, b) => (a.itemNumber < b.itemNumber ? -1 : 1))
        .map((i) => {
          return {
            ...i,
            itemValue: i.itemValue ?? "",
            status: true,
          };
        })
    );
  }, [fetchActionApi.state?.status, fetchUsersActionApi.state?.status]);

  const getRankBadge = (rankCategory?: RankCategory) => {
    if (rankCategory === "bronze") {
      return bronze;
    }

    if (rankCategory === "silver") {
      return silver;
    }

    if (rankCategory === "gold") {
      return gold;
    }
  };

  const getBadge = (acquiredBadgeType?: AcquiredBadgeType) => {
    if (acquiredBadgeType === "green") {
      return green;
    }
    if (acquiredBadgeType === "yellow") {
      return yellow;
    }
    if (acquiredBadgeType === "rainbow") {
      return rainbow;
    }

    return gray;
  };

  const getBreadCrumbRank = (rankCategory?: RankCategory) => {
    if (rankCategory === "bronze") {
      return "ブロンズランクアクション";
    }

    if (rankCategory === "silver") {
      return "シルバーランクアクション";
    }

    if (rankCategory === "gold") {
      return "ゴールドランクアクション";
    }
  };

  const setQuizResult = (quizNumber: number, isCorrect: boolean) => {
    setQuizParams([...quizParams, { quizNumber, isCorrect }]);
  };

  const setInputResult = (args: TextFormResult) => {
    const lst = inputParams.map((i) => {
      if (i.itemId === args.name) {
        i.itemValue = args.value;
        i.status = args.status;
      }

      return i;
    });

    setInputParams(lst);
  };

  const setBackgroundColor = () => {
    if (isInterest) {
      return { background: "#66C6BB", border: "none" };
    }

    return {};
  };

  useEffect(() => {
    if (validate()) {
      setButtonClass(`${classes.button}`);
    } else {
      setButtonClass(`${classes.button} ${classes.disabled}`);
    }
  }, [quizParams, inputParams]);

  const validate = () => {
    if (actionResponse == null) {
      return false;
    }

    if (actionResponse.actionFormatType === "quiz") {
      return actionResponse.quizList.length === quizParams.length;
    }

    if (actionResponse.actionFormatType === "input") {
      return inputParams.every((i) => {
        return i.status;
      });
    }

    return false;
  };

  const submit = () => {
    if (!validate()) {
      return;
    }

    if (actionResponse == null) {
      return;
    }

    if (actionResponse.actionFormatType === "quiz") {
      postUsersActionApi.post({
        actionId: actionResponse.actionId,
        quizList: quizParams,
      });
    }
    if (actionResponse.actionFormatType === "input") {
      postUsersActionApi.post({
        actionId: actionResponse.actionId,
        inputList: inputParams.map((i) => {
          return {
            itemId: i.itemId,
            itemValue: (() => {
              const typeStr = ["date", "string"];
              if (typeStr.includes(i.itemType)) {
                return i.itemValue;
              }

              const v = toNumber(i.itemValue);

              if (v == null || isNaN(v)) {
                return null;
              }

              return v;
            })(),
            mandatory: i.mandatory,
          };
        }),
      });
    }
  };

  useEffect(() => {
    if (postUsersActionApi.state?.status === "success") {
      if (postUsersActionApi.state?.data?.[0].isAcquiredBadgeType) {
        setShowResult("badge");
      } else {
        closeResult();
      }
    }
  }, [postUsersActionApi.state?.status]);

  const closeResult = () => {
    if (
      showResult === "badge" &&
      postUsersActionApi.state?.data?.[0].rankTitle != null &&
      postUsersActionApi.state?.data?.[0].rankTitle !== ""
    ) {
      setShowResult("rankup");
    } else {
      postUsersActionApi.reset();
      navigate("/action", {
        state: {
          tab: actionType === "rank" ? "rank": "plus",
        },
      });
    }
  };

  const clickBookmark = (flag: boolean) => {
    setIsInterest(flag);
    if (actionResponse?.actionId == null) {
      return;
    }
    postUsersBookmarkApi.post({
      actionId: actionResponse?.actionId,
      isInterest: flag,
    });
    setModalState({
      isOpen: true,
      isInterest: flag,
    });
  };

  return (
    <>
      {showResult !== "hide" && postUsersActionApi.state?.data?.[0] != null ? (
        <BadgeResult
          type={showResult}
          data={postUsersActionApi.state.data[0]}
          close={closeResult}
        />
      ) : (
        <div className={classes.container}>
          <Header />
          <div className={classes.pageHeader}>
            <div className={classes.actionSubtitle}>
              <img
                className={classes.rankCategoryBadge}
                src={getRankBadge(rankCategory)}
                alt={getRankBadge(rankCategory)}
              />
              {actionResponse?.actionCategory}
            </div>
            <div className={classes.actionTitle}>
              {/* {actionResponse?.actionSubTitle} */}
              {actionResponse?.actionMainTitle}
            </div>
            <div className={classes.badgeContainer}>
              <img
                className={classes.acquiredBadgeType}
                src={getBadge(acquiredBadgeType)}
                alt={getBadge(acquiredBadgeType)}
              />
              <div
                className={classes.heartContainer}
                style={setBackgroundColor()}
              >
                {isInterest ? (
                  <img
                    src={yellowHeart}
                    alt="yellow heart"
                    className={classes.heartIcon}
                    onClick={() => clickBookmark(false)}
                  />
                ) : (
                  <img
                    src={whiteHeart}
                    alt="white heart"
                    className={classes.heartIcon}
                    onClick={() => clickBookmark(true)}
                  />
                )}
              </div>
            </div>
            <TeacherComment
              image={teacherImage}
              comment={[actionResponse?.actionExplation ?? ""]}
            />
            <div className={classes.outline}>
              <div className={classes.line}>
                <div className={classes.label}>STEP1</div>
                <div className={classes.text}>{actionResponse?.stepLabel1}</div>
              </div>
              <div className={classes.line}>
                <div className={classes.label}>STEP2</div>
                <div className={classes.text}>{actionResponse?.stepLabel2}</div>
              </div>
              <div className={classes.caption}>
                ※STEP2だけでもアクション完了できます
              </div>
            </div>
          </div>
          <div className={classes.step1Block}>
            <div className={classes.stepHeader}>
              <div className={classes.label}>STEP1</div>
              <div className={classes.title}>{actionResponse?.stepLabel1}</div>
            </div>
            <div className={classes.contents}>
              {actionResponse?.themes.map((d, index) => {
                return (
                  <>
                    <div className={classes.contentsHeader}>
                      <div className={classes.label}>やること{index + 1}</div>
                      <div className={classes.title}>{d.themeName}</div>
                    </div>
                    <div className={classes.body}>
                      {d.details.map((detail) => {
                        return (
                          <>
                            <div className={classes.block}>
                              <div className={classes.label}>
                                {detail.header}
                              </div>
                              <div className={classes.text}>{detail.body}</div>
                            </div>
                          </>
                        );
                      })}
                      <a className={classes.button} href={d.suggentionUrl} target="_blank" rel="noopener noreferrer">
                        {d.suggentionHeader}
                      </a>
                    </div>
                    {index < actionResponse.themes.length - 1 && (
                      <div className={classes.nextBlock}>
                        <img
                          className={classes.image}
                          src={arrowImage}
                          alt={arrowImage}
                        />
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
          {/* クイズ */}
          {actionResponse?.actionFormatType === "quiz" && (
            <div className={classes.quizBlock}>
              <div className={classes.stepHeader}>
                <div className={classes.label}>STEP2</div>
                <div className={classes.title}>
                  {actionResponse?.stepLabel2}
                </div>
              </div>
              <div className={classes.comment}>
                <TeacherComment
                  image={teacherImage}
                  comment={[
                    "問題は全部で10問！すべて回答するとバッジがもらえます",
                    "再挑戦は何度でも可能です♪",
                  ]}
                />
              </div>

              {actionResponse?.quizList.map((d, index) => {
                return (
                  <div className={classes.quiz} key={`quiz-${index}`}>
                    <Quiz
                      data={d}
                      index={index + 1}
                      total={actionResponse.quizList.length}
                      submit={(result) => setQuizResult(d.quizNumber, result)}
                    />
                  </div>
                );
              })}
            </div>
          )}
          {/* end */}
          {/* 入力フォーム */}
          {actionResponse?.actionFormatType === "input" && (
            <div className={classes.inputBlock}>
              <div className={classes.stepHeader}>
                <div className={classes.label}>STEP2</div>
                <div className={classes.title}>
                  {actionResponse?.stepLabel2}
                </div>
              </div>
              <div className={classes.comment}>
                <TeacherComment
                  image={teacherImage}
                  comment={[
                    "必須の項目をすべて入力するとレインボーバッジがもらえます",
                  ]}
                />
              </div>
              {inputParams.map((input, index) => {
                return (
                  <div
                    className={classes.inputContainer}
                    key={`input-${index}`}
                  >
                    <ActionInput
                      name={input.itemId}
                      mandatory={input.mandatory}
                      title={input.itemName}
                      value={input.itemValue}
                      itemType={input.itemType}
                      caption={input.explanation}
                      onChange={(args) => setInputResult(args)}
                      align="right"
                      periodicity={input.periodicity}
                      unit={input.unit}
                      dropListValue={input.dropListValue}
                    />
                  </div>
                );
              })}
            </div>
          )}
          {/* end */}
          <div className={classes.buttonBlock}>
            <a className={buttonClass} onClick={submit}>
              アクションを完了する
            </a>
          </div>
          <FavoriteModal></FavoriteModal>
          <BreadCrumb
            data={[
              "みらいいコンシェル",
              "アクション",
              `${getBreadCrumbRank(rankCategory)}`,
              actionResponse?.actionSubTitle,
            ]}
          />
        </div>
      )}
    </>
  );
};

export default ActionDetail;
