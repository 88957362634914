import { atom } from "jotai";
import { State } from "./State";
import { fetchBase } from "./BaseApi";

export type FinancePartner = {
  fpId: number;
  isFinancialPartner:boolean;
  firstName: string;
  lastName: string;
  fpProfile: string;
  imageFile: string;
  fpImageData: string;
};

type FetchFinancePartnerParams = {
  fpId: number;
};

const url = "api/finance-partners";

export const financePartnerAtom = atom<State<FinancePartner> | null>(null);

export const fetchFinancePartner = () => {
  return fetchBase<FinancePartner, FetchFinancePartnerParams>(
    url,
    financePartnerAtom
  );
};
