import { atom } from "jotai";
import { postBase } from "./BaseApi";
import { State } from "./State";

export const logoutAtom = atom<State<null> | null>(null);

export const logout = () => {
  const url = "api/logout";
  return postBase<null, null>(url, logoutAtom);
};
