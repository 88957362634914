import React, { useEffect, useState } from "react";
import charIcon from "./../../static/images/chara1.svg";
import charIcon2 from "./../../static/images/chara2.svg";
import classes from "./FirstSetting.module.scss";
import TextForm, { TextFormResult } from "../../atoms/text-form/TextForm";
import RadioButton from "../../atoms/radio-button/RadioButton";
import SimpleButton from "../../atoms/simple-button/SimpleButton";
import SelectBox, { SelectBoxOption } from "../../atoms/select-box/SelectBox";
import CheckBox, { CheckBoxItem } from "../../atoms/checkbox/CheckBox";
import { useNavigate } from "react-router-dom";
import {
  SchoolType,
  childItem,
  childSchools,
  gender,
  grade,
  isChildrenSection,
  isNotChildrenSection,
  relationships,
  schoolType,
  universityType,
  userTopic,
} from "./FirstSetting-const";
import {
  FamilyProfile,
  User,
  UserParam,
  UserTopic,
  fetchUser,
  postUsers,
} from "../../api/UsersApi";
import Header from "../../molecules/header/Header";
import { validators } from "../../common/utility/validator.util";
import TeacherComment from "../../molecules/teacher-commnet/TeacherComment";
import RadioInCheckbox, {
  RadioInCheckBoxItem,
} from "../../organism/radio-in-checkbox/RadioInCheckBox";
import RadioInCheckBox from "../../organism/radio-in-checkbox/RadioInCheckBox";
type Form = {
  familyName: string | null;
  myRelationship: string | null;
  myBirthday: string | null;
  existsPartner: string | null;
  partnerRelationship: string | null;
  partnerBirthday: string | null;
  children: Child[] | null;
};

type Child = {
  childGeneder: string | null;
  childBirthday: string | null;
  currentGrade: string | null;
  kindergarden: string | null;
  primarySchool: string | null;
  juniorSchool: string | null;
  highSchool: string | null;
  university: string | null;
  schools: SchoolType[] | null;
};

type Section = {
  no: string;
  title: string;
};

const FirstSetting = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const [sections, setSections] = useState<Section[] | null>(null);
  const [formState, setFormState] = useState<number>(1);
  const fetchUserApi = fetchUser();
  const postUser = postUsers();
  const [answer, setAnswer] = useState<Form>({
    familyName: null,
    myRelationship: null,
    myBirthday: null,
    existsPartner: null,
    partnerRelationship: null,
    partnerBirthday: null,
    children: null,
  });

  const [topic, setTopic] = useState<RadioInCheckBoxItem[]>(userTopic);
  const [disabled, setDisabled] = useState<boolean>(true);

  const dateValidator = validators.date;

  const changeAnswer = (e: TextFormResult | SelectBoxOption) => {
    setAnswer((prev) => ({ ...prev, [e.name]: e.value }));
  };

  const changeChild = (e: TextFormResult | SelectBoxOption, index: number) => {
    if (answer.children == null) {
      return;
    }
    const newChildren = [...answer.children];
    newChildren[index] = { ...newChildren[index], [e.name]: e.value };
    if (e.name === "currentGrade") {
      newChildren[index] = {
        ...newChildren[index],
        ["kindergarden"]: "none",
        ["primarySchool"]: "none",
        ["juniorSchool"]: "none",
        ["highSchool"]: "none",
        ["university"]: "none",
        ["schools"]: createDisplayedSchools(e.value),
      };
    }
    setAnswer((prev) => ({ ...prev, children: newChildren }));
  };

  const createDisplayedSchools = (currentGrade: string) => {
    if (currentGrade === "none") {
      return null;
    } else if (
      currentGrade.includes("kinder") ||
      currentGrade.includes("preschool")
    ) {
      return childSchools;
    } else if (currentGrade.includes("primary")) {
      return childSchools.slice(1);
    } else if (currentGrade.includes("junior")) {
      return childSchools.slice(2);
    } else if (currentGrade.includes("high")) {
      return childSchools.slice(3);
    } else {
      return childSchools.slice(4);
    }
  };

  const changeCheckBoxValue = (e: RadioInCheckBoxItem) => {
    const updatedTopic = topic?.map((t) => {
      if (t.id === userTopic.filter((i) => i.id === e.id)[0].id) {
        return e;
      }
      return t;
    });
    setTopic(updatedTopic);
  };

  const validateState1 = () => {
    return (
      answer.familyName == null ||
      answer.familyName == "" ||
      answer.myBirthday == null ||
      answer.myBirthday == "" ||
      answer.myRelationship == null ||
      !dateValidator(answer.myBirthday).status
    );
  };

  const validateState2 = () => {
    if (answer.existsPartner === "いない") {
      return false;
    }

    return (
      answer.existsPartner == null ||
      answer.partnerRelationship == null ||
      answer.partnerBirthday == null ||
      answer.partnerBirthday == "" ||
      !dateValidator(answer.partnerBirthday).status
    );
  };

  const validateState3 = () => {
    if (answer.children == null) {
      return false;
    }

    return answer.children.some((child) => {
      const check = () => {
        if (child.currentGrade == null || child.currentGrade === "none") {
          return true;
        } else if (
          child.currentGrade.includes("kinder") ||
          child.currentGrade.includes("preschool")
        ) {
          return (
            child.kindergarden == "none" ||
            child.primarySchool == "none" ||
            child.juniorSchool == "none" ||
            child.highSchool == "none" ||
            child.university == "none"
          );
        } else if (child.currentGrade.includes("primary")) {
          return (
            child.primarySchool == "none" ||
            child.juniorSchool == "none" ||
            child.highSchool == "none" ||
            child.university == "none"
          );
        } else if (child.currentGrade.includes("junior")) {
          return (
            child.juniorSchool == "none" ||
            child.highSchool == "none" ||
            child.university == "none"
          );
        } else if (child.currentGrade.includes("high")) {
          return child.highSchool == "none" || child.university == "none";
        } else {
          return child.university == "none";
        }
      };

      return (
        check() ||
        child.childGeneder == null ||
        child.childBirthday == null ||
        child.childBirthday == "" ||
        !dateValidator(child.childBirthday).status
      );
    });
  };

  const updateButtonDisabled = () => {
    if (formState === 1) {
      return validateState1();
    } else if (formState === 2) {
      return validateState2();
    } else if (formState === 3) {
      return validateState3();
    } else if (formState === 4) {
      return validateState1() || validateState2() || validateState3();
    }
    return false;
  };

  const submit = () => {
    const familyProfile: FamilyProfile[] = [
      {
        familyName: answer.familyName,
        familyRelationship: "myself",
        childrenRelationship: answer.myRelationship,
        gender: null,
        birthdate: answer.myBirthday,
        currentGrade: "none",
        kindergarden: "none",
        primarySchool: "none",
        juniorSchool: "none",
        highSchool: "none",
        university: "none",
      },
    ];

    const existsPertner = answer.existsPartner === "いる";

    // パートナーがいるならリクエストに追加
    if (existsPertner) {
      familyProfile.push({
        familyName: null,
        familyRelationship: "partner",
        childrenRelationship: answer.partnerRelationship,
        gender: null,
        birthdate: answer.partnerBirthday,
        currentGrade: "none",
        kindergarden: "none",
        primarySchool: "none",
        juniorSchool: "none",
        highSchool: "none",
        university: "none",
      });
    }

    // 子供がいるならリクエストに追加
    if (answer.children != null) {
      const children: FamilyProfile[] = answer?.children?.map((child) => {
        return {
          familyName: null,
          familyRelationship: "children",
          childrenRelationship: null,
          gender: child.childGeneder,
          birthdate: child.childBirthday,
          currentGrade: child.currentGrade,
          kindergarden: child.kindergarden,
          primarySchool: child.primarySchool,
          juniorSchool: child.juniorSchool,
          highSchool: child.highSchool,
          university: child.university,
        };
      });
      familyProfile.push(...children);
    }

    const userTopic: UserTopic[] = topic.map((item) => {
      return {
        topicId: Number(item.id),
        isInterest: item.value,
      };
    });

    const requestParam: UserParam = {
      isPartner: existsPertner,
      userTopic,
      familyProfile,
    };

    postUser.post(requestParam);
  };

  const changeState = () => {
    // 子供がいない場合は子供用のformを出さないので、stateが2の時は4にする
    if (!user?.isChildren && formState === 2) {
      setFormState(formState + 2);
    } else {
      setFormState(formState + 1);
    }
    setDisabled(updateButtonDisabled());
  };

  // 初回にユーザ情報を取得する
  useEffect(() => {
    fetchUserApi.fetch();
  }, []);

  useEffect(() => {
    if (fetchUserApi.state?.data == null) {
      return;
    }
    setUser(fetchUserApi.state?.data?.[0]);
  }, [fetchUserApi.state?.data]);

  useEffect(() => {
    if (user?.isChildren == null) {
      return;
    }

    // 子供の有無でフォームの数を変化させる
    if (user?.isChildren) {
      setSections(isChildrenSection);
    } else {
      setSections(isNotChildrenSection);
    }

    // 子供の数だけ子供用フォームの配列を作る
    setAnswer((prev) => ({
      ...prev,
      children: Array.from({ length: user.numberOfChildren }, () =>
        createChildren()
      ),
    }));
  }, [user]);

  // フォームの変化ごとにボタンの活性制御を行う
  useEffect(() => {
    setDisabled(updateButtonDisabled());
  }, [answer, formState]);

  useEffect(() => {
    if (postUser.state?.status === "success") {
      navigate("/action");
    }
  }, [postUser.state]);

  const createChildren = () => {
    return {
      childGeneder: null,
      childBirthday: null,
      currentGrade: "none",
      kindergarden: "none",
      primarySchool: "none",
      juniorSchool: "none",
      highSchool: "none",
      university: "none",
      schools: null,
    };
  };

  return (
    <div className={classes.container}>
      <Header hideMenu={true} />
      <div className={classes.headerSub}>
        <TeacherComment
          image={charIcon}
          comment={[
            "「将来に備えて今日からできるアクション」をご提案します！",
            "あなたとあなたの家族について教えてください（3分程度）",
          ]}
        ></TeacherComment>
      </div>
      <div className={classes.selectArea}>
        <div className={classes.inputWrapper}>
          <div className={classes.heading}>
            <div className={classes.headingNo}>
              <span className={classes.headingNumerator}>
                {sections?.[0].no}
              </span>
              <span className={classes.headingDenominator}>
                /{sections?.length}
              </span>
            </div>
            <div className={classes.headingTitle}>あなたのこと</div>
          </div>
          <div className={classes.input}>
            <TextForm
              name="familyName"
              value={answer.familyName}
              color="white"
              label="ニックネーム"
              subLabel="15文字以内"
              onChange={changeAnswer}
              validator={(value: string) => {
                return {
                  status: value.length <= 15,
                  message: "15文字以内にしてください",
                };
              }}
            ></TextForm>
          </div>
          <div className={classes.input}>
            <RadioButton
              name="myRelationship"
              value={answer.myRelationship}
              items={relationships}
              id="myRelationship"
              label="あなたは子供にとって・・・"
              onChange={changeAnswer}
            ></RadioButton>
          </div>
          <div className={classes.input}>
            <TextForm
              name="myBirthday"
              value={answer.myBirthday ?? "1990-01-01"}
              placeholder="例）1990-01-01"
              color="white"
              label="誕生日"
              type="text"
              subLabel="西暦＋月日を半角で入力してください。"
              onChange={changeAnswer}
              validator={dateValidator}
            ></TextForm>
          </div>
        </div>
        {formState >= 2 && (
          <div className={classes.inputWrapper}>
            <div className={classes.heading}>
              <div className={classes.headingNo}>
                <span className={classes.headingNumerator}>
                  {sections?.[1].no}
                </span>
                <span className={classes.headingDenominator}>
                  /{sections?.length}
                </span>
              </div>
              <div className={classes.headingTitle}>
                パートナー・配偶者のこと
              </div>
            </div>
            <div className={classes.input}>
              <RadioButton
                name="existsPartner"
                id="existsPartner"
                value={answer.existsPartner}
                // TODO: boolean用のラジオボタン作るべきか or 単一チェックボックスでやるか
                items={[
                  {
                    display: "いる",
                    value: "いる",
                  },
                  {
                    display: "いない",
                    value: "いない",
                  },
                ]}
                label="現在、パートナー・配偶者は…"
                onChange={changeAnswer}
              ></RadioButton>
            </div>
            {answer.existsPartner === "いる" && (
              <>
                <div className={classes.input}>
                  <RadioButton
                    name="partnerRelationship"
                    id="partnerRelationship"
                    value={answer.partnerRelationship}
                    items={relationships}
                    label="パートナー・配偶者は子どもにとって…"
                    onChange={changeAnswer}
                  ></RadioButton>
                </div>
                <div className={classes.input}>
                  <TextForm
                    name="partnerBirthday"
                    value={answer.partnerBirthday ?? "1990-01-01"}
                    placeholder="例）1990-01-01"
                    type="text"
                    color="white"
                    label="パートナー・配偶者の誕生日"
                    subLabel="西暦＋月日を半角で入力してください。"
                    onChange={changeAnswer}
                    validator={dateValidator}
                  ></TextForm>
                </div>
              </>
            )}
          </div>
        )}
        {user?.isChildren && formState >= 3 && (
          <div className={classes.inputWrapper}>
            <div className={classes.heading}>
              <div className={classes.headingNo}>
                <span className={classes.headingNumerator}>
                  {sections?.[2].no}
                </span>
                <span className={classes.headingDenominator}>
                  /{sections?.length}
                </span>
              </div>
              <div className={classes.headingTitle}>子どものこと</div>
            </div>
            {answer.children?.map((child, childIndex) => (
              <React.Fragment key={"children" + "-" + childIndex}>
                <div className={classes.input}>
                  <RadioButton
                    name="childGeneder"
                    id={`childGeneder-${childIndex}`}
                    value={child.childGeneder}
                    items={gender}
                    label={`【第${childIndex + 1}子】子どもの性別`}
                    onChange={(e) => changeChild(e, childIndex)}
                  ></RadioButton>
                </div>
                <div className={classes.input}>
                  <TextForm
                    name="childBirthday"
                    value={child.childBirthday ?? "2010-01-01"}
                    placeholder="例）2010-01-01"
                    type="text"
                    color="white"
                    label="子どもの誕生日"
                    subLabel="西暦＋月日を半角で入力してください。"
                    onChange={(e) => changeChild(e, childIndex)}
                    validator={dateValidator}
                  ></TextForm>
                </div>
                <div className={classes.input}>
                  <div>
                    <SelectBox
                      id={"grade" + childIndex}
                      label={"子どもの進学先(予定）"}
                      defaultValue="none"
                      title={{
                        name: "現在の学年",
                        value: "currentGrade",
                      }}
                      option={grade}
                      onChange={(e) => changeChild(e, childIndex)}
                    ></SelectBox>
                  </div>
                  {child.schools &&
                    child.schools.map((school, schoolIndex) => (
                      <div key={"schools" + childIndex + "-" + schoolIndex}>
                        <SelectBox
                          id={"school" + "-" + childIndex + "-" + schoolIndex}
                          defaultValue="none"
                          title={school}
                          option={
                            school.value === "university"
                              ? universityType
                              : schoolType
                          }
                          onChange={(e) => changeChild(e, childIndex)}
                        ></SelectBox>
                      </div>
                    ))}
                </div>
              </React.Fragment>
            ))}
          </div>
        )}
        {formState >= 4 && (
          <div className={classes.inputWrapper}>
            <div className={classes.heading}>
              <div className={classes.headingNo}>
                {user?.isChildren ? (
                  <>
                    <span className={classes.headingNumerator}>
                      {sections?.[3].no}
                    </span>
                    <span className={classes.headingDenominator}>
                      /{sections?.length}
                    </span>
                  </>
                ) : (
                  <>
                    <span className={classes.headingNumerator}>
                      {sections?.[2].no}
                    </span>
                    <span className={classes.headingDenominator}>
                      /{sections?.length}
                    </span>
                  </>
                )}
              </div>
              <div className={classes.headingTitle}>興味・関心</div>
            </div>
            <div className={classes.input}>
              <RadioInCheckBox
                label="今、興味があるのは…"
                subLabel="複数選択可"
                checkItem={topic}
                childItem={childItem}
                onChange={changeCheckBoxValue}
              ></RadioInCheckBox>
            </div>
            <div className={classes.headerSub}>
              <TeacherComment
                image={charIcon2}
                comment={[
                  "ご協力ありがとうございました♪",
                  `${answer.familyName}
                さんファミリーにぴったりなアクションをご提案いたします`,
                ]}
              ></TeacherComment>
            </div>
          </div>
        )}
        {formState !== 4 ? (
          <div className={classes.buttonArea}>
            <SimpleButton
              label="次の質問へ"
              width="80%"
              isRadius={true}
              disabled={disabled}
              onClick={changeState}
            ></SimpleButton>
          </div>
        ) : (
          <div className={classes.buttonArea}>
            <SimpleButton
              label="アクションを確認する"
              width="80%"
              maxWidth="343rem"
              isRadius={true}
              disabled={disabled}
              onClick={submit}
            ></SimpleButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default FirstSetting;
