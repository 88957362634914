import React, { useState } from "react";
import classes from "./Quiz.module.scss";
import { QuizType } from "../../api/ActionsApi";
import circleBlack from "../../static/images/circle.svg";
import circleWhite from "../../static/images/circle-white.svg";
import circleGray from "../../static/images/circle-gray.svg";
import closeBlack from "../../static/images/close.svg";
import closeWhite from "../../static/images/close-white.svg";
import closeGray from "../../static/images/close-gray.svg";

type Props = {
  data: QuizType;
  index: number;
  total: number;
  submit: (result: boolean) => void;
};
const Quiz = (props: Props) => {
  const [result, setResult] = useState<boolean>();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [answer, setAnswer] = useState<boolean>();
  const getButton = (type: boolean) => {
    if (isSubmit) {
      if (answer === type) {
        return type ? circleWhite : closeWhite;
      }
      return type ? circleGray : closeGray;
    }

    return type ? circleBlack : closeBlack;
  };

  const getButtonClass = (type: boolean) => {
    if (!isSubmit) {
      return;
    }

    if (type !== answer) {
      return;
    }

    return answer === props.data.answer ? classes.correct : classes.incorrect;
  };

  const getAnswerLabelStyle = () => {
    if (result) {
      return {
        color: "#1784E9",
      };
    }

    return {
      color: "#747474",
    };
  };

  const submit = (answer: boolean) => {
    if (isSubmit) {
      return;
    }
    if (props.data.answer === answer) {
      setResult(true);
      props.submit(true);
    } else {
      setResult(false);
      props.submit(false);
    }

    setAnswer(answer);
    setIsSubmit(true);
  };
  return (
    <div className={classes.container}>
      <div className={classes.question}>
        <div className={classes.header}>
          <div className={classes.index}>Q{props.index} </div>
          <div className={classes.slash}>/</div>
          <div className={classes.total}>{props.total}</div>
        </div>
        <div className={classes.text}>{props.data.question}</div>
        <div className={classes.buttonBlock}>
          <a
            className={`${classes.button} ${getButtonClass(true)}`}
            onClick={() => submit(true)}
          >
            <img
              className={classes.buttonIcon}
              src={getButton(true)}
              alt={getButton(true)}
            />
          </a>

          <a
            className={`${classes.button} ${getButtonClass(false)}`}
            onClick={() => submit(false)}
          >
            <img
              className={classes.buttonIcon}
              src={getButton(false)}
              alt={getButton(false)}
            />
          </a>
        </div>
      </div>
      {isSubmit && (
        <div className={classes.answer}>
          <div className={classes.summary}>
            <div className={classes.label} style={getAnswerLabelStyle()}>
              {result ? "正解！" : "不正解・・・"}
            </div>
            <div className={classes.text}>
              答えは{props.data.answer ? " ◯" : " ×"}
            </div>
          </div>
          <div className={classes.body}>{props.data.explanation}</div>
        </div>
      )}
    </div>
  );
};

export default Quiz;
