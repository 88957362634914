import React, { CSSProperties, useEffect, useState } from "react";
import classes from "./MyPage.module.scss";
import Header from "../../molecules/header/Header";
import UserInfo from "../../organism/user-info/UserInfo";
import FrameTemplate from "../../organism/frame-template/FrameTemplate";
import ActionIcon from "../../static/images/action-icon.png";
import rainbowBadge from "../../static/images/rainbowBadge.png";
import yellowBadge from "../../static/images/yellowBadge.png";
import greenBadge from "../../static/images/greenBadge.png";
import adequacy25 from "../../static/images/adequacy25.svg";
import adequacy33 from "../../static/images/adequacy33.svg";
import adequacy50 from "../../static/images/adequacy50.svg";
import adequacy67 from "../../static/images/adequacy67.svg";
import adequacy75 from "../../static/images/adequacy75.svg";
import adequacy100 from "../../static/images/adequacy100.svg";
import Avatar from "../../atoms/avatar/Avatar";
import { useNavigate } from "react-router-dom";
import graph from "../../static/images/adequecyGraph.svg";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";
import { User, fetchUser } from "../../api/UsersApi";
import { DiagnosisResults, fetchDiagnosis } from "../../api/UsersDiagnosisApi";
import TeacherComment from "../../molecules/teacher-commnet/TeacherComment";
import chara from "../../static/images/chara1.svg";
import DiagnosisNavigate from "../../molecules/diagnosis-navigate/DiagnosisNavigate";
import { FinancePartner, fetchFinancePartner } from "../../api/FinancePartners";
import Card from "../../molecules/card/Card";
import lockImage from "../../static/images/lock-icon.svg";

const MyPage = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const [results, setResults] = useState<DiagnosisResults | null>(null);
  const [userName, setUserName] = useState<string | null>(null);
  const [financePartner, setFinancePartner] = useState<FinancePartner | null>(
    null
  );
  const fetchUserApi = fetchUser();
  const diagnosisApi = fetchDiagnosis();
  const financePartnerApi = fetchFinancePartner();

  useEffect(() => {
    fetchUserApi.fetch();
  }, []);

  useEffect(() => {
    if (fetchUserApi.state?.data == null) {
      return;
    }
    setUser(fetchUserApi.state?.data?.[0]);
  }, [fetchUserApi.state?.data]);

  useEffect(() => {
    if (user == null) {
      return;
    }
    const diagnosisType = user.diagnosisType;
    diagnosisApi.fetch({
      diagnosisType,
    });
    const userName = user.familyProfile.filter(
      (f) => f.familyRelationship === "myself"
    )[0].familyName;

    if (userName == null) {
      return;
    }

    setUserName(userName);

    const fpId = user.fpId;
    financePartnerApi.fetch({
      fpId,
    });
  }, [user]);

  useEffect(() => {
    if (diagnosisApi.state?.data == null) {
      return;
    }
    setResults(diagnosisApi.state?.data[0]);
  }, [diagnosisApi.state?.data]);

  useEffect(() => {
    if (financePartnerApi.state?.data == null) {
      return;
    }
    setFinancePartner(financePartnerApi.state.data[0]);
  }, [financePartnerApi.state?.data]);

  const createAdequacy = () => {
    if (user?.numberOfChildren == null) return;

    if (user?.numberOfChildren > 0) {
      switch (user?.currentRank) {
        case "platinum":
          return adequacy100;
        case "gold":
          return adequacy75;
        case "silver":
          return adequacy50;
        case "bronze":
          return adequacy25;
      }
    } else {
      switch (user?.currentRank) {
        case "platinum":
          return adequacy100;
        case "gold":
          return adequacy67;
        case "silver":
          return adequacy33;
      }
    }
    return adequacy25;
  };

  const checkChildrenAndRank = () => {
    if (user == null) {
      return;
    }
    return user.numberOfChildren === 0 && user.currentRank === "bronze";
  };

  const createLifePlanStyle = () => {
    if (user?.currentRank === "platinum") {
      return { backgroundColor: "#ED7E99" };
    }
    return { backgroundColor: "#008E7E" };
  };

  const avaterColor = ["#6BD2C0", "#7CC0FF", "#EE8699", "#D08FEF"];
  return (
    <div className={classes.container}>
      {user != null && (
        <>
          <div className={classes.headerArea}>
            <Header />
            <div className={classes.userInfo}>
              {userName && (
                <UserInfo userRank={user.currentRank} userName={userName} />
              )}
            </div>
          </div>
          <div className={classes.contentContainer}>
            {user.numberOfCompletedAction === 0 && (
              <div className={classes.teacherComment}>
                <TeacherComment
                  image={chara}
                  comment={["まずはアクションを1つ完了してみましょう"]}
                ></TeacherComment>
              </div>
            )}
            <div className={classes.content}>
              <div className={classes.frameContainer}>
                <FrameTemplate
                  frameTitle="アクション"
                  onClick={() => navigate("/action")}
                >
                  <div className={classes.action}>
                    <img src={ActionIcon} alt="actionIcon" />
                    <div className={classes.actionNum}>
                      {user.numberOfCompletedAction}
                    </div>
                    <div className={classes.actionText}>完了</div>
                  </div>
                </FrameTemplate>
              </div>
              <div className={classes.frameContainer}>
                <FrameTemplate
                  frameTitle="バッジ"
                  noVector={true}
                >
                  <div className={classes.badge}>
                    <div className={classes.badgeCircle}>
                      <img
                        src={rainbowBadge}
                        alt="badge"
                        className={classes.badgeImg}
                      />
                      <div
                        className={classes.inCircle}
                        style={{ backgroundColor: "#FFF7D4" }}
                      >
                        {user.numberOfCompletedRainbowbadge}
                      </div>
                    </div>
                    <div className={classes.badgeCircle}>
                      <img
                        src={yellowBadge}
                        alt="badge"
                        className={classes.badgeImg}
                      />
                      <div
                        className={classes.inCircle}
                        style={{ backgroundColor: "#FFF7D4" }}
                      >
                        {user.numberOfCompletedYellowbadge}
                      </div>
                    </div>
                    <div className={classes.badgeCircle}>
                      <img
                        src={greenBadge}
                        alt="badge"
                        className={classes.badgeImg}
                      />
                      <div
                        className={classes.inCircle}
                        style={{ backgroundColor: "#E1F8F3" }}
                      >
                        {user.numberOfCompletedGreenbadge}
                      </div>
                    </div>
                  </div>
                </FrameTemplate>
              </div>
            </div>
            <div className={classes.frameContainer}>
              <FrameTemplate
                frameTitle="家族の基本情報"
                onClick={() => navigate("/family-setting")}
              >
                <div className={classes.familyContainer}>
                  {user.familyProfile.map(
                    (family, index) =>
                      family.familyName != null && (
                        <div key={index} className={classes.family}>
                          <Avatar
                            userName={family.familyName}
                            backgroundColor={avaterColor[index]}
                            width="48rem"
                            height="48rem"
                            fontSize="12rem"
                          ></Avatar>
                        </div>
                      )
                  )}
                </div>
              </FrameTemplate>
            </div>
            <div className={classes.frameContainer}>
              <FrameTemplate
                frameTitle="ライフプラン"
                frameContentPadding="10rem 0 0"
                onClick={() => !checkChildrenAndRank() && navigate("/lifeplan")}
              >
                <div className={classes.adequecyContainer}>
                  {/* 子供が0人の場合、ブロンズはロックがかかる */}
                  {/* それ以外は、ライフプランの達成率を表示 */}
                  {checkChildrenAndRank() ? (
                    <div className={classes.rock}>
                      <img
                        className={classes.icon}
                        src={lockImage}
                        alt={lockImage}
                      />
                      <div className={classes.caption}>次のランクで解放</div>
                    </div>
                  ) : (
                    <>
                      <img
                        src={createAdequacy()}
                        alt="adequency"
                        className={classes.adequency}
                      />
                      <img src={graph} alt="graph" className={classes.graph} />
                    </>
                  )}
                  <div
                    className={classes.adequencyMessage}
                    style={createLifePlanStyle()}
                  >
                    {user.currentRank === "platinum" ? (
                      <span>ライフプランの完成！</span>
                    ) : (
                      <span>ランクアップするごとにライフプランが充実</span>
                    )}
                  </div>
                </div>
              </FrameTemplate>
            </div>
            <div className={classes.frameContainer}>
              {user.diagnosisType === "no_diagnosis" ? (
                <DiagnosisNavigate />
              ) : (
                <FrameTemplate
                  frameTitle={"おカネの行動診断結果"}
                  onClick={() => navigate("/result")}
                >
                  <div className={classes.diagnosis}>
                    <div className={classes.diagnosisTitle}>あなたは</div>
                    <div className={classes.diagnosisContent}>
                      {results && results.typeName}タイプ
                    </div>
                  </div>
                </FrameTemplate>
              )}
            </div>
            <div className={classes.bannerCounselling}>
              <div className={classes.bubble}>
                {financePartner?.isFinancialPartner
                  ? "なんでもお気軽にご相談ください"
                  : "お金の専門家を味方につけましょう"}
              </div>
              <Card
                name={
                  financePartner?.isFinancialPartner
                    ? `${financePartner?.lastName} ${financePartner?.firstName}`
                    : "担当のパートナーは\n登録されていません"
                }
                subName="担当フィナンシャルプランナー"
                image={financePartner?.fpImageData}
                content={financePartner?.fpProfile}
              />
            </div>
          </div>
          <div className={classes.breadCrumb}>
            <BreadCrumb data={["みらいいコンシェル", "マイページ"]} />
          </div>
        </>
      )}
    </div>
  );
};

export default MyPage;
