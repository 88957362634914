import React, { useEffect, useState } from "react";
import classes from "./BadgeResult.module.scss";
import { PostUsersAction } from "../../api/UsersActionApi";
import badgeGetImage from "../../static/images/badge-get.png";
import rankupGetImage from "../../static/images/rankup-get.png";
import checkIcon from "../../static/images/check-green.svg";
import { useNavigate } from "react-router-dom";

type Props = {
  type: "badge" | "rankup";
  data: PostUsersAction;
  close: () => void;
};

type DisplayData = {
  title: string;
  image: string;
  explanation: string;
  optionalText: string[];
};

const BadgeResult = (props: Props) => {
  const [data, setData] = useState<DisplayData>();
  const navigate = useNavigate();
  useEffect(() => {
    if (props.type === "badge") {
      setData({
        title: props.data.badgeTitle,
        image: props.data.badgeImageData,
        explanation: props.data.badgeExplanation,
        optionalText: [],
      });
    } else if (props.type === "rankup") {
      setData({
        title: "おめでとうございます！",
        image: props.data.rankImageData,
        explanation: props.data.rankTitle,
        optionalText: props.data.rankExplanation,
      });
    }
  }, [props]);

  const toLifeplan = () => {
    props.close();
    navigate("/lifeplan");
  };

  return (
    <div className={classes.container}>
      <div
        className={classes.card}
        style={{ minHeight: props.type === "badge" ? "330rem" : "510rem" }}
      >
        <img
          className={classes.ribbonImage}
          src={props.type === "badge" ? badgeGetImage : rankupGetImage}
          alt={props.type === "badge" ? badgeGetImage : rankupGetImage}
        />
        <div className={classes.title}>{data?.title}</div>
        <img className={classes.image} src={data?.image} alt={data?.image} />
        <div className={classes.text}>{data?.explanation}</div>
        {data != null && data.optionalText.length > 0 && (
          <div className={classes.optionalContainer}>
            {data.optionalText.map((text) => (
              <div className={classes.line} key={text}>
                <img
                  className={classes.checkIcon}
                  src={checkIcon}
                  alt={checkIcon}
                />
                <span className={classes.optionalText}>{text}</span>
              </div>
            ))}
          </div>
        )}
        {props.type === "rankup" && (
          <div className={classes.lifeplanContainer} onClick={toLifeplan}>
            最新のライフプランを見てみる
          </div>
        )}
        <div className={classes.close} onClick={props.close}>
          閉じる
        </div>
      </div>
    </div>
  );
};

export default BadgeResult;
