import React from "react";
import classes from "./TeacherComment.module.scss";
type Props = {
  image: string;
  comment: string[] | undefined;
};

const TeacherComment = (props: Props) => {
  return (
    <div className={classes.container}>
      <img className={classes.icon} src={props.image} alt={props.image} />
      <div className={classes.comment}>
        {props.comment?.map((c, index) => (
          <div key={`teacher-comment-${index}`}>{c}</div>
        ))}
      </div>
    </div>
  );
};

export default TeacherComment;
