import React, { useState } from "react";
import classes from "./Textarea.module.scss";

interface TextareaProps {
  label?: string;
  subLabel?: string;
  rows?: number;
  placeholder?: string;
  content?: string;
  onChange?: (value: string) => void;
}

const Textarea = (props: TextareaProps) => {
  const [value, setValue] = useState<string>(props.content ?? "");
  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
    props.onChange?.(event.target.value);
  };
  return (
    <div className={classes.container}>
      <label className={classes.label}>{props.label}</label>
      <label className={classes.subLabel}>{props.subLabel}</label>
      <textarea
        className={classes.textarea}
        rows={props.rows ?? 10}
        placeholder={props.placeholder}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default Textarea;
