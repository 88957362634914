import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./Question.module.scss";
import { questionList } from "./question-const";
import { postDiagnosis } from "../../api/UsersDiagnosisApi";
import { diagnosisLoading } from "../../state/diagnosis-loading/DiagnosisLoadingAtom";
import { useAtom } from "jotai";
import { fetchUser } from "../../api/UsersApi";

//ロゴファイルの動的読み込み
const logos = Array.from({ length: 15 }).map((_, index) => {
  const logoNumber = index + 1;
  return require(`../../static/images/questionLogo${logoNumber}.png`);
});

//ロゴファイルの返却
const getQuestionLogoPath = (questionNumber: number) => {
  return logos[questionNumber - 1];
};

const MAX_PROGRESS = 98; // プログレスバーをピッタリするために調整

const Question = () => {
  //Navigate
  const navigate = useNavigate();

  //ScoreProvider

  //ProgressBar
  const progressContainerRef = useRef<HTMLDivElement | null>(null);
  const [progress, setProgress] = useState(0);
  const [startNumberPosition, setStartNumberPosition] = useState({});

  //QuestionComponent
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [totalQuestion, setTotalQuestion] = useState<number>(15);
  const [question, setQuestion] = useState("");
  const [answers, setAnswers] = useState<string[]>([]);
  const [selectedAnswers, setSelectedAnswers] = useState<
    Array<{ questionNumber: number; answerNumber: number }>
  >([]);
  const diagnosisPostApi = postDiagnosis();
  const [loadingState, setLoadingState] = useAtom(diagnosisLoading);
  const fetchUserApi = fetchUser();

  //回答結果保存
  const handleAnswerClick = (answerValue: string) => {
    const answerIdx = answers.findIndex((answer) => answer === answerValue) + 1;
    setSelectedAnswers((prev) => {
      // すでに選択された回答を見つける
      const existingAnswer = prev.find(
        (answer) => answer.questionNumber === currentQuestion
      );

      // 選択された回答がすでに存在する場合、それを更新する
      if (existingAnswer) {
        existingAnswer.answerNumber = answerIdx;
        return [...prev];
      }
      // それ以外の場合、新しい回答を追加する
      return [
        ...prev,
        { questionNumber: currentQuestion, answerNumber: answerIdx },
      ];
    });

    if (currentQuestion <= totalQuestion) {
      setCurrentQuestion((prev) => prev + 1);
    }
  };

  //１つ前に戻る
  const handlePreviousClick = () => {
    if (currentQuestion === 1) {
      navigate("/diagnosis");
    } else if (currentQuestion > 1) {
      setCurrentQuestion((prev) => prev - 1);
    }
  };

  const createRequestParams = () => {
    const requestPamras: { [key: string]: number } = {};
    selectedAnswers.forEach((item) => {
      requestPamras[String(item.questionNumber)] = item.answerNumber;
    });
    return {
      answers: requestPamras,
    };
  };

  useEffect(() => {
    if (fetchUserApi.state?.data == null) {
      fetchUserApi.fetch();
    }

    setTotalQuestion(fetchUserApi.state?.data?.[0].isChildren ? 15 : 8);
  }, []);

  useEffect(() => {
    const progressContainer = progressContainerRef.current;
    if (progressContainer) {
      const progressContainerWidth = progressContainer.offsetWidth;
      const newLeftValue = (progress / 100) * progressContainerWidth - 12;
      setStartNumberPosition({
        left: `${newLeftValue}px`,
      });
    }
  }, [progress, classes.progressContainer]);

  useEffect(() => {
    //プログレスバー更新
    currentQuestion === 1
      ? setProgress(0)
      : setProgress((currentQuestion / totalQuestion) * MAX_PROGRESS);

    //回答リスト更新
    const currentData = questionList.find(
      (q) => q.questionNo === currentQuestion
    );
    if (currentData) {
      setQuestion(currentData.question);
      const answerStrings: string[] = currentData.answerList.map(
        (a) => a.answer
      );
      setAnswers(answerStrings);
    }

    if (currentQuestion > totalQuestion) {
      const requestParams = createRequestParams();
      diagnosisPostApi.post(requestParams);
    }
  }, [currentQuestion]);

  useEffect(() => {
    if (
      diagnosisPostApi.state?.status === "success" &&
      progress > totalQuestion
    ) {
      setLoadingState(true);
      navigate("/result");
    }
  }, [diagnosisPostApi.state?.data]);

  return (
    <div className={classes.questionContainer}>
      {currentQuestion <= totalQuestion && (
        <>
          <div className={classes.progressBarContainer}>
            <div
              className={classes.progressContainer}
              ref={progressContainerRef}
            >
              <div
                className={classes.progressBar}
                style={{ width: `${progress}%` }}
              ></div>
              <span className={classes.startNumber} style={startNumberPosition}>
                {currentQuestion}
              </span>
              <span className={classes.endNumber}>{totalQuestion}</span>
            </div>
          </div>
          <div className={classes.question}>
            <div className={classes.questionNo}>Q{currentQuestion}</div>
            <div
              dangerouslySetInnerHTML={{ __html: question }}
              className={classes.questionContent}
            ></div>
          </div>
          <div className={classes.logoContainer}>
            <img src={getQuestionLogoPath(currentQuestion)} alt="Logo" />
          </div>
          <div className={classes.answerButtonContainer}>
            {answers.map((answer, index) => (
              <button
                key={`question-${currentQuestion}-answer-${index}`} // 一意のkeyを生成
                onClick={() => handleAnswerClick(answer)}
                className={`${classes.selectButton} ${
                  selectedAnswers[currentQuestion - 1]?.answerNumber ===
                  index + 1
                    ? classes.selectedButton
                    : ""
                }`}
              >
                {answer}
              </button>
            ))}
          </div>
          <div className={classes.linkContainer} onClick={handlePreviousClick}>
            <p>ひとつ前に戻る</p>
          </div>
        </>
      )}
    </div>
  );
};

export default Question;
