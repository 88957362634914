import { atom, useAtom } from "jotai";
import { State } from "./State";
import axios from "axios";
import { fetchBase, postBase } from "./BaseApi";
import { ChartSetType } from "./UsersEducationChartApi";

export type UsersRevenueExpenseChart = {
  currentAge: number;
  chartSet: ChartSetType[];
};

const uri = "api/users/revenue-expense-chart";

export const fetchUsersRevenueExpenseChartAtom =
  atom<State<UsersRevenueExpenseChart> | null>(null);
export const fetchUsersRevenueExpenseChart = () => {
  return fetchBase<UsersRevenueExpenseChart>(
    uri,
    fetchUsersRevenueExpenseChartAtom
  );
};
