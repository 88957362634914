import classes from "./Card.module.scss";
import React from "react";

interface CardProps {
  name?: string;
  subName?: string;
  image?: string;
  content?: string;
}

const Card = (props: CardProps) => {
  // 改行マーカー `|` に基づいて名前を分割し、リストでレンダリング
  const renderName = () => {
    const nameParts = props.name ? props.name.split('\n') : [];
    return nameParts.map((part, index) => (
      <React.Fragment key={index}>
        {part}
        {index < nameParts.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  return (
    <div className={classes.container}>
      <div className={classes.cardHeader}>
        {props.image && (
          <img src={props.image} alt="" className={classes.images} />
        )}
        <div className={classes.nameArea}>
          <div className={classes.subName}>{props.subName}</div>
          <div className={classes.name}>
            {renderName()}
          </div>
        </div>
      </div>
      <div className={classes.content}>{props.content}</div>
    </div>
  );
};

export default Card;