import QuestionHeader from "../../organism/questionHeader/QuestionHeader";
import Question from "../question/Question";
import React from "react";
import classes from "./QuestionTop.module.scss";

const QuestionTop = () => {
  return (
    <div>
      <QuestionHeader />
      <Question />
    </div>
  );
};

export default QuestionTop;
