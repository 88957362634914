import React, { useEffect, useState } from "react";
import classes from "./SideMenu.module.scss";

import SimpleButton from "../../atoms/simple-button/SimpleButton";
import exportIcon from "./../../static/images/exportIcon.png";
import menuLine from "../../static/images/menu-line.png";
import { useAtom } from "jotai";
import { sideMenuAtom } from "../../state/side-menu/SideMenuAtom";
import cancelImage from "../../static/images/cancel-icon.png";
import UserInfo from "../user-info/UserInfo";
import { useNavigate } from "react-router-dom";
import { fetchUser, fetchUserAtom } from "../../api/UsersApi";
import { logout } from "../../api/LogoutApi";
import { login } from "../../api/LoginApi";
import { useLoading } from "../../pages/loading/Loading";
import lockIcon from "../../static/images/lock-icon-white.svg";

const SideMenu = () => {
  const navigate = useNavigate();
  const loading = useLoading();
  const usersApi = fetchUser();
  const [lockLifeplan, setLockLifeplan] = useState<boolean>(true);

  useEffect(() => {
    loading.show();
    usersApi.fetch();
  }, []);

  useEffect(() => {
    if (usersApi.state?.data == null) {
      return;
    }

    setLockLifeplan(
      usersApi.state?.data[0].currentRank === "bronze" &&
        !usersApi.state.data[0].isChildren
    );
  }, [usersApi.state?.data]);

  const transit = (url: string) => {
    // TODO: LoginStateをいじらないとLogin画面にはいかない
    setState({
      ...state,
      isShow: false,
    });
    navigate(url);
  };

  const userApi = fetchUser();
  const logoutApi = logout();
  const loginApi = login();

  // TODO: バッジリスト、お問い合わせは順次開放
  const menuList = [
    {
      name: "アクション",
      isLock: false,
      onclick: () => transit("/action"),
    },
    // {
    //   name: "バッジリスト",
    //   onclick: () => transit("/badge"),
    // },
    {
      name: "ライフプラン",
      isLock: lockLifeplan,
      onclick: () => transit("/lifeplan"),
    },
    {
      name: "お金の行動診断結果",
      isLock: false,
      onclick: () =>
        userApi.state?.data?.[0].diagnosisType === "no_diagnosis"
          ? transit("/diagnosis")
          : transit("/result"),
    },
    {
      name: "家族の基本情報",
      isLock: false,
      onclick: () => transit("/family-setting"),
    },
    // {
    //   name: "お問い合わせ",
    //   onclick: () => transit("/inquiry"),
    //   icon: exportIcon,
    // },
    {
      name: "ログアウト",
      isLock: false,
      onclick: () => {
        logoutApi.post();
        loginApi.reset();
        transit("/login");
      },
    },
  ];

  const [state, setState] = useAtom(sideMenuAtom);

  const cancel = () => {
    setState({
      ...state,
      isShow: false,
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerArea}>
        <img
          src={cancelImage}
          alt={cancelImage}
          className={classes.cancelImage}
          onClick={cancel}
        />
      </div>
      <div className={classes.userInfo}>
        <UserInfo userName={state.userName} />
      </div>
      <div className={classes.buttonArea}>
        <SimpleButton
          label="マイページ"
          onClick={() => transit("/my-page")}
          width="80%"
          isRadius={true}
        ></SimpleButton>
      </div>
      <div className={classes.menuListContainer}>
        <div className={classes.lineImageContainer}>
          <img src={menuLine} alt="menuLine" className={classes.lineImage} />
        </div>
        <div className={classes.menuList}>
          {menuList.map((menu, index) => (
            <>
              {menu.isLock ? (
                <div key={index} className={`${classes.name} ${classes.lock}`}>
                  <img src={lockIcon} alt={lockIcon} />
                  {menu.name}
                </div>
              ) : (
                <div
                  key={index}
                  className={classes.name}
                  onClick={menu.onclick}
                >
                  {menu.name}
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
