import { atom, useAtom } from "jotai";
import { State } from "./State";
import axios from "axios";
import { fetchBase, postBase } from "./BaseApi";

export type UsersRetirementFee = {
  totalRetirementFee: number;
  totalRetirementYearlyBased: number;
  totalRetirementMonthlyBased: number;
};

const uri = "api/users/retirement-fee";

export const fetchUsersRetirementFeeAtom =
  atom<State<UsersRetirementFee> | null>(null);
export const fetchUsersRetirementFee = () => {
  return fetchBase<UsersRetirementFee>(uri, fetchUsersRetirementFeeAtom);
};
