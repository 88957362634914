import classes from "./BreadCrumb.module.scss";
import arrow from "../../static/images/gt.svg";
import React from "react";

type Props = {
  data: (string | undefined)[];
};

const BreadCrumb = (props: Props) => {
  const isNotLast = (index: number) => {
    return index !== props.data.length - 1;
  };

  const getClass = (index: number) => {
    if (isNotLast(index)) {
      return classes.normal;
    }

    return classes.strong;
  };
  return (
    <div className={classes.container}>
      {props.data.map((d, index) => (
        <div key={index}>
          <span className={getClass(index)}>{d ?? ""}</span>
          {isNotLast(index) && (
            <img className={classes.icon} src={arrow} alt={arrow} />
          )}
        </div>
      ))}
    </div>
  );
};

export default BreadCrumb;
