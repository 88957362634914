import React, { CSSProperties, useEffect, useState } from "react";
import classes from "./ActionCard.module.scss";
import arrowIcon from "../../static/images/arrow-right.svg";
import whiteHeart from "../../static/images/WhiteHeart.svg";
import yellowHeart from "../../static/images/YellowHeart.svg";
import { postBookmark } from "../../api/UsersBookmark";
import { useAtom } from "jotai";
import { favoriteModalStateAtom } from "../../state/modal/ModalAtom";
import FavoriteModal from "../../organism/favorite-modal/FavoriteModal";
import { RankCategory } from "../../api/UsersActionApi";
import rockIcon from "../../static/images/rockIcon.png";

export type ActionCardType = {
  mainLabel: string;
  label: string;
  status: "INITIAL" | "PROGRESS" | "DONE";
  title: string;
  completedDate?: string;
  badge: string;
  isInterest?: boolean;
  actionId: number;
  disabled: boolean;
  rank: RankCategory;
  onClick?: () => void;
};

type Props = {
  data: ActionCardType;
};

const ActionCard = (props: Props) => {
  const [isInterest, setIsInterest] = useState<boolean | undefined>(
    props.data.isInterest
  );
  const updateBookmark = postBookmark();
  const [modalState, setModalState] = useAtom(favoriteModalStateAtom);

  const clickBookmark = (flag: boolean) => {
    setIsInterest(flag);
    updateBookmark.post({
      actionId: props.data.actionId,
      isInterest: flag,
    });
    setModalState({
      isOpen: true,
      isInterest: flag,
    });
  };

  const changeBackground = () => {
    if (props.data.disabled) {
      return {
        backgroundColor: "rgba(224, 224, 224, 1)",
        color: "rgba(116, 116, 116, 1)",
      };
    }
    return {};
  };

  return (
    <div className={classes.container} style={changeBackground()}>
      <div className={classes.header}>
        <div className={classes.label}>
          {isInterest ? (
            <img
              src={yellowHeart}
              alt="yellow heart"
              className={classes.heartIcon}
              onClick={() => !props.data.disabled && clickBookmark(false)}
            />
          ) : (
            <img
              src={whiteHeart}
              alt="white heart"
              className={classes.heartIcon}
              onClick={() => !props.data.disabled && clickBookmark(true)}
            />
          )}
          <div className={classes.triangle}></div>
          {props.data.label}
        </div>
        {props.data.completedDate != null && (
          <div className={classes.date}>{props.data.completedDate}</div>
        )}
        {props.data.disabled && (
          <div className={classes.tagContainer}>
            <img src={rockIcon} alt="rockIcon" />
            <div className={classes.tag}>
              {props.data.rank === "gold" ? "ゴールド限定" : "シルバー限定"}
            </div>
          </div>
        )}
      </div>
      <div
        className={classes.body}
        onClick={() => !props.data.disabled && props.data.onClick?.()}
      >
        <img className={classes.badge} src={props.data.badge} />
        <div className={classes.title}>{props.data.title}</div>
        {!props.data.disabled && (
          <img className={classes.arrow} src={arrowIcon} />
        )}
      </div>
      <FavoriteModal></FavoriteModal>
    </div>
  );
};

export default ActionCard;
