import { atom } from "jotai";
import { State } from "./State";
import { fetchBase, postBase } from "./BaseApi";

export type DiagnosisPostRequestType = { [key: string]: number };
export type DiagnosisPostRequest = {
  answers: DiagnosisPostRequestType;
};

export type DiagnosisFetchRequestType = {
  diagnosisType: string;
};

export type DiagnosisResults = {
  childActionExpl: string;
  childActionTitle: string;
  childTendExpl: string;
  childTendTitle: string;
  childTendency: string;
  diagnosisType: string;
  impactExpl: string;
  impactTitle: string;
  moneyActionExpl: string;
  moneyActionTitle: string;
  moneyTendExpl: string;
  moneyTendTitle: string;
  moneyTendency: string;
  typeFeature: string;
  typeName: string;
  typeTitle: string;
};

export const postDiagnosisAtom = atom<State<DiagnosisPostRequest> | null>(null);

export const postDiagnosis = () => {
  const uri = "api/users/diagnosis";
  return postBase<DiagnosisPostRequest, DiagnosisPostRequest>(
    uri,
    postDiagnosisAtom
  );
};

export const fetchDiagnosisAtom = atom<State<DiagnosisResults> | null>(null);

export const fetchDiagnosis = () => {
  const uri = "api/users/diagnosis";
  return fetchBase<DiagnosisResults, DiagnosisFetchRequestType>(
    uri,
    fetchDiagnosisAtom
  );
};
