import React, { useEffect, useState } from "react";
import styles from "./DiagnosisTop.module.scss";
import Footer from "../../molecules/footer/Footer";
import DiagnosisTopContent from "../../organism/diagnosis-top/diagnosis-top-content/DiagnosisTopContent";
import DiagnosisTopHeader from "../../organism/diagnosis-top/diagnosis-top-header/DiagnosisTopHeader";
import DiagnosisTopHeaderNoChildren from "../../organism/diagnosis-top/diagnosis-top-header-for-no-children/DiagnosisTopHeaderForNochildren";
import { fetchUser } from "../../api/UsersApi";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";

function DiagnosisTop() {
  const fetchUserApi = fetchUser();
  const [isChildren, setIsChildren] = useState<boolean>(false);

  useEffect(() => {
    if (fetchUserApi.state?.data == null) {
      fetchUserApi.fetch();
    }
  }, []);

  useEffect(() => {
    if (fetchUserApi.state?.data?.[0].isChildren == null) {
      return;
    }
    setIsChildren(fetchUserApi.state?.data?.[0].isChildren);
  }, [fetchUserApi.state?.data]);
  return (
    <div className={styles.container}>
      {isChildren ? <DiagnosisTopHeader /> : <DiagnosisTopHeaderNoChildren />}
      <div className={styles.topWrapper}>
        <DiagnosisTopContent />
      </div>
      <div className={styles.breadCrumb}>
        <BreadCrumb data={["みらいいコンシェル", "おカネの行動診断"]} />
      </div>
    </div>
  );
}

export default DiagnosisTop;
