import React, { useEffect, useState } from "react";
import Accordion from "../../molecules/accordion/Accordion";
import classes from "./FamilySetting.module.scss";
import Header from "../../molecules/header/Header";
import Footer from "../../molecules/footer/Footer";
import teacherImage from "../../static/images/teacher-ok.svg";
import arrowRight from "../../static/images/arrow-right.svg";
import plusIcon from "../../static/images/plus-circle.svg";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";
import Avatar from "../../atoms/avatar/Avatar";
import { UserFinanceItem, fetchUsersFinance } from "../../api/UsersFinanceApi";
import PageTitle from "../../atoms/page-title/PageTitle";
import { FamilyProfile, fetchUser } from "../../api/UsersApi";
import TeacherComment from "../../molecules/teacher-commnet/TeacherComment";
import { fetchUsersGoal } from "../../api/UsersGoalApi";

type Money = {
  label: string;
  children: {
    label: string;
    value: string;
  }[];
};

const financeOrder = [
  "収入",
  "固定費",
  "変動費",
  "老後の収入",
  "将来の生活費",
  "将来の必要額",
  "資産形成",
];

const FamilySetting = () => {
  const [myself, setMyself] = useState<FamilyProfile>();
  const [partner, setPartner] = useState<FamilyProfile>();
  const [children, setChildren] = useState<FamilyProfile[]>();
  const { state, fetch } = fetchUsersFinance();
  const fetchUserApi = fetchUser();
  const fetchGoalApi = fetchUsersGoal();
  const [money, setMoney] = useState<Money[]>([]);
  useEffect(() => {
    fetch();
    fetchUserApi.fetch();
    fetchGoalApi.fetch();
  }, []);

  useEffect(() => {
    if (state?.data != null) {
      const data = state.data[0].userFinanceItems;
      const categoryMap: { [key: string]: UserFinanceItem[] } = {};
      data.forEach((d) => {
        if (!(d.categoryName in categoryMap)) {
          categoryMap[d.categoryName] = [];
        }

        categoryMap[d.categoryName].push(d);
      });

      const createValue = (item: UserFinanceItem) => {
        if (item.itemValue == null) {
          return "未入力";
        }

        if (item.periodicity == null) {
          return `${item.itemValue}${item.unit}`;
        }

        return `${item.itemValue}${item.unit}/${item.periodicity}`;
      };

      setMoney(
        Object.keys(categoryMap)
          .sort((a, b) => {
            const aIndex = financeOrder.indexOf(a);
            const bIndex = financeOrder.indexOf(b);

            if (aIndex === -1 && bIndex === -1) {
              return 0;
            }

            if (aIndex === -1) {
              return 1;
            }

            if (bIndex === -1) {
              return -1;
            }

            return aIndex < bIndex ? -1 : 1;
          })
          .map((key) => {
            return {
              label: key,
              children: categoryMap[key]
                .sort((a, b) => {
                  return a.itemNumber < b.itemNumber ? -1 : 1;
                })
                .map((item) => {
                  return {
                    label: item.itemName,
                    value: createValue(item),
                  };
                }),
            };
          })
      );
    }

    if (fetchUserApi.state?.data != null) {
      const familyProfile = fetchUserApi.state?.data[0].familyProfile;

      if (familyProfile == null || familyProfile.length === 0) {
        return;
      }

      const myself = familyProfile.find(
        (v) => v.familyRelationship === "myself"
      );
      const partner = familyProfile.find(
        (v) => v.familyRelationship === "partner"
      );
      const children = familyProfile.filter(
        (v) => v.familyRelationship === "children"
      );
      setMyself(myself);
      setPartner(partner);
      setChildren(children);
    }
  }, [state?.status, fetchUserApi.state?.status]);

  return (
    <div className={classes.container}>
      <Header />
      <PageTitle title="家族の基本情報" />
      <div className={classes.body}>
        {myself != null && (
          <div className={classes.block}>
            <div className={classes.label}>あなた</div>
            <div className={classes.list}>
              <div className={classes.line}>
                <div className={classes.icon}>
                  <Avatar userName={myself.familyName ?? ""} />
                </div>
                <div className={classes.nameBlock}>
                  <div className={classes.name}>{myself.familyName}</div>
                </div>
                {/* 
                <img
                  className={classes.arrowIcon}
                  src={arrowRight}
                  alt={arrowRight}
                />
                */}
              </div>
            </div>
          </div>
        )}
        {partner != null && (
          <div className={classes.block}>
            <div className={classes.label}>パートナー・配偶者</div>
            <div className={classes.list}>
              <div className={classes.line}>
                <div className={classes.icon}>
                  <Avatar
                    backgroundColor={"#84C4FF"}
                    userName={partner.familyName ?? ""}
                  />
                </div>
                <div className={classes.nameBlock}>
                  <div className={classes.name}>{partner.familyName}</div>
                </div>
                {/* 
                <img
                  className={classes.arrowIcon}
                  //src={arrowRight}
                  //alt={arrowRight}
                />
                */}
              </div>
            </div>
          </div>
        )}
        {children != null && children.length > 0 && (
          <div className={classes.block}>
            <div className={classes.label}>子ども</div>
            <div className={classes.list}>
              {children.map((child, index) => {
                return (
                  <div
                    className={classes.line}
                    key={`${child.familyName}-${index}`}
                  >
                    <div className={classes.icon}>
                      <Avatar
                        backgroundColor={index === 0 ? "#EE8699" : "#D08FEF"}
                        userName={child.familyName ?? ""}
                      />
                    </div>
                    <div className={classes.nameBlock}>
                      <div className={classes.name}>{child.familyName}</div>
                      {/*  <div className={classes.option}>みらいいパーク未連携</div> */}
                    </div>
                    {/*
                    <img
                      className={classes.arrowIcon}
                      src={arrowRight}
                      alt={arrowRight}
                    />
                    */}
                  </div>
                );
              })}
              {/* TODO 3/23時点ではOFF
              <div className={classes.line}>
                <img className={classes.icon} src={plusIcon} alt={plusIcon} />
                <div className={classes.nameBlock}>
                  <div className={classes.addChild}>子どもを追加</div>
                </div>
                <img
                  className={classes.arrowIcon}
                  src={arrowRight}
                  alt={arrowRight}
                />
              </div>
            */}
            </div>
          </div>
        )}
        {fetchGoalApi.state?.data != null &&
        fetchGoalApi.state?.data.length > 0 ? (
          <div className={classes.block}>
            <div className={classes.label}>ゴール</div>
            <div className={classes.list}>
              {fetchGoalApi.state.data.map((d) => {
                return (
                  <div key={d.goalId} className={classes.line}>
                    {d.goalContent}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className={classes.block}>
            <div className={classes.label}>ゴール</div>
            <div className={classes.disableList}>
              <div className={classes.disableBlock}>
                表示できる項目がまだありません
              </div>
            </div>
          </div>
        )}

        <div className={classes.block}>
          <div className={classes.label}>おカネ</div>
          <div className={classes.listWithoutPad}>
            <Accordion list={money} />
          </div>
        </div>

        {/* TODO 3/23時点ではOFF

        <TeacherComment
          image={teacherImage}
          comment={[
            "「おカネ」と「ゴール」の項目がすべてそろいましたね！時々見直してアップデートしましょう♪",
          ]}
        />
        */}
      </div>

      <div className={classes.breadCrumb}>
        <BreadCrumb data={["みらいいコンシェル", "家族の基本情報"]} />
      </div>
    </div>
  );
};

export default FamilySetting;
