import { atom } from "jotai";
import { fetchBase, postBase } from "./BaseApi";
import { State } from "./State";
import Action from "../pages/action/Action";

type Theme = {
  themeId: number;
  themeName: string;
  suggentionHeader: string;
  suggentionUrl: string;
  details: {
    subThemeId: number;
    header: string;
    body: string;
  }[];
};

export type QuizType = {
  quizNumber: number;
  answer: boolean;
  actionId: number;
  question: string;
  explanation: string;
};

export type ItemType =
  | "number"
  | "positive_number"
  | "year"
  | "month"
  | "day"
  | "date"
  | "string"
  | "drop_list";

export type ActionInputType = {
  itemName: string;
  itemNumber: number;
  itemId: string;
  itemType: ItemType;
  itemValue: string;
  periodicity: string;
  unit: string;
  categoryName: string;
  explanation: string;
  mandatory: "required" | "optional";
  dropListValue: string;
};

export type Action = {
  actionId: number;
  actionCategory: string;
  actionFormatType: "quiz" | "input";
  actionMainTitle: string;
  actionSubTitle: string;
  actionExplation: string;
  stepLabel1: string;
  stepLabel2: string;
  themes: Theme[];
  quizList: QuizType[];
  inputList: ActionInputType[];
};

type Params = {
  actionId: number;
};

export const fetchActionAtom = atom<State<Action> | null>(null);
export const fetchAction = () => {
  const uri = "api/actions";
  return fetchBase<Action, Params>(uri, fetchActionAtom);
};
