import React from 'react';
import classes from './QuestionHeader.module.scss';
import questionHeaderLogo from '../../static/images/questionHeaderLogo.png';

function QuestionHeader() {
  return (
    <div className={classes.questionHeaderContainer}>
      <img src={questionHeaderLogo} alt='Logo' />
    </div>
  );
}
export default QuestionHeader;
