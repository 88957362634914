import { atom, useAtom } from "jotai";
import { State } from "./State";
import axios from "axios";
import { fetchBase, postBase } from "./BaseApi";
import { ChartSetType } from "./UsersEducationChartApi";

export type UsersLifeplanAdviceChart = {
  userId: string;
  financeAdvice: string;
};

const uri = "api/users/lifeplan-advice";

export const fetchUsersLifeplanAdviceChartAtom =
  atom<State<UsersLifeplanAdviceChart> | null>(null);
export const fetchUsersLifeplanAdviceChart = () => {
  return fetchBase<UsersLifeplanAdviceChart>(
    uri,
    fetchUsersLifeplanAdviceChartAtom
  );
};
