import React, { CSSProperties } from "react";
import classes from "./Avatar.module.scss";

type AvatarProps = {
  userName?: string;
  backgroundColor?: string;
  width?: string;
  height?: string;
  fontSize?: string;
  onClick?: () => void;
};

const Avatar = (props: AvatarProps) => {
  const createStyle = () => {
    return {
      width: props.width,
      height: props.height,
      fontSize: props.fontSize,
      backgroundColor: props.backgroundColor,
    };
  };
  return (
    <div
      className={classes.menuIcon}
      onClick={props.onClick}
      style={createStyle()}
    >
      {props.userName?.slice(0, 3)}
    </div>
  );
};

export default Avatar;
