import axios, { AxiosError } from "axios";
import { atom, PrimitiveAtom, useAtom } from "jotai";
import { State } from "./State";
import { errorAtom } from "../pages/error-handler/ErrorHandler";

// クエリパラメータと、前回リクエスト時間（UNIXTIME）を保持しておく
let p: { [uri: string]: { param: string; last: number } } = {};
const setP = (newP: { [uri: string]: { param: string; last: number } }) => {
  p = { ...p, ...newP };
};

export const fetchBase = <T, P = undefined>(
  uri: string,
  atom: PrimitiveAtom<State<T> | null>
) => {
  const [state, setState] = useAtom(atom);
  const [errorState, setErrorState] = useAtom(errorAtom);

  const fetch = (params?: P) => {
    const paramString = JSON.stringify(params);
    const now = new Date().getTime();
    // APIリクエストが多重に飛ぶのを防止する
    // クエリパラメータが同じリクエストが1秒以内に来た場合スキップ
    if (uri in p && p[uri].param === paramString && now - p[uri].last < 1000) {
      return;
    }
    setP({ [uri]: { param: paramString, last: now } });
    setState({
      status: "processing",
      data: null,
      error: null,
    });
    (async () => {
      try {
        const response = await axios.get(uri, { params });
        setState({
          status: "success",
          data: response.data.response,
          error: null,
        });
      } catch (error) {
        setErrorState(error);
        setState({
          status: "error",
          data: null,
          error: error,
        });
      }
    })();
  };

  return { state, fetch };
};

export const postBase = <T, P = undefined>(
  uri: string,
  atom: PrimitiveAtom<State<T> | null>
) => {
  const [state, setState] = useAtom(atom);
  const [errorState, setErrorState] = useAtom(errorAtom);

  const post = (params?: P) => {
    (async () => {
      try {
        setState({
          status: "processing",
          data: null,
          error: null,
        });
        const response = await axios.post(uri, params);
        setState({
          status: "success",
          data: response.data.response,
          error: null,
        });
      } catch (error) {
        setErrorState(error);
        setState({
          status: "error",
          data: null,
          error: error,
        });
      }
    })();
  };

  const reset = () => {
    setState(null);
  };

  return { state, post, reset };
};

export const putBase = <T, P = undefined>(
  uri: string,
  atom: PrimitiveAtom<State<T> | null>
) => {
  const [state, setState] = useAtom(atom);
  const [errorState, setErrorState] = useAtom(errorAtom);

  const put = (params?: P) => {
    (async () => {
      try {
        setState({
          status: "processing",
          data: null,
          error: null,
        });
        const response = await axios.put(uri, params);
        setState({
          status: "success",
          data: response.data.response,
          error: null,
        });
      } catch (error) {
        setErrorState(error);
        setState({
          status: "error",
          data: null,
          error: error,
        });
      }
    })();
  };

  const reset = () => {
    setState(null);
  };

  return { state, put, reset };
};

export const deleteBase = <T, P = undefined>(
  uri: string,
  atom: PrimitiveAtom<State<T> | null>
) => {
  const [state, setState] = useAtom(atom);
  const [errorState, setErrorState] = useAtom(errorAtom);

  const deleteItem = (params?: P) => {
    (async () => {
      if (state?.status === "processing") {
        return;
      }
      try {
        setState({
          status: "processing",
          data: null,
          error: null,
        });
        const response = await axios.delete(uri, { params });
        setState({
          status: "success",
          data: response.data.response,
          error: null,
        });
      } catch (error) {
        setErrorState(error);
        setState({
          status: "error",
          data: null,
          error: error,
        });
      }
    })();
  };

  return { state, deleteItem };
};
