import { useEffect, useState } from "react";
import React from "react";
import classes from "./SelectForm.module.scss";

export type SelectFormProps = {
  name: string;
  value: string | null;
  list: string[];
  unit?: string;
  periodicity?: string;
  validator?: (value: string) => { status: boolean; message?: string };
  onChange: (args: SelectFormResult) => void;
};

export type SelectFormResult = {
  status: boolean;
  value: string;
  name: string;
};
type ValidatorResult = {
  status: boolean;
  message?: string;
};

const SelectForm = (props: SelectFormProps) => {
  const [value, setValue] = useState<string>();
  const [result, setResult] = useState<ValidatorResult>({ status: true });
  const [onTouch, setOnTouch] = useState(false);
  const [suffix, setSuffix] = useState<string>("");

  useEffect(() => {
    const value = (() => {
      if (props.value != null && props.value !== "") {
        return props.value;
      }

      if (props.list.length > 0) {
        return props.list[0];
      }

      return "";
    })();
    change(value);
  }, []);

  useEffect(() => {
    setSuffix(
      props.periodicity != null
        ? `${props.unit ? `${props.unit}／` : ""}${props.periodicity}`
        : props.unit || ""
    );
  }, [props.periodicity, props.unit]);

  const change = (value: string) => {
    const validatorResult = props.validator?.(value) ?? { status: true };
    setValue(value);
    setResult(validatorResult);
    props.onChange({ status: validatorResult.status, value, name: props.name });
  };

  const onChange = (value: string) => {
    setOnTouch(true);
    change(value);
  };

  return (
    <div className={classes.container}>
      <select value={value} onChange={(e) => onChange(e.target.value)}>
        {props.list.map((v) => {
          return (
            <option value={v} key={v}>
              {v !== "" ? `${v}${suffix}` : "未選択"}
            </option>
          );
        })}
      </select>

      <div className={classes.error}>
        {!onTouch || result.status ? "" : result.message}
      </div>
    </div>
  );
};

export default SelectForm;
