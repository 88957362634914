import { atom } from "jotai";
import { fetchBase } from "./BaseApi";
import { State } from "./State";

export type UserFinanceItem = {
  itemId: string;
  categoryName: string;
  itemName: string;
  itemNumber: number;
  unit: string;
  periodicity: string;
  itemValue: number;
};

export type UsersFinance = {
  userId: string;
  userFinanceItems: UserFinanceItem[];
};

export const fetchUsersFinanceAtom = atom<State<UsersFinance> | null>(null);
export const fetchUsersFinance = () => {
  const uri = "api/users/finance";
  return fetchBase<UsersFinance>(uri, fetchUsersFinanceAtom);
};
