import React, { useEffect, useState } from "react";
import classes from "./FavoriteModal.module.scss";
import ModalTemplate from "../modal-template/ModalTemplate";
import favorite from "../../static/images/ButtonFavorite.svg";
import unfavorite from "../../static/images/UnFavoriteButton.svg";
import {
  favoriteModalStateAtom,
  modalStateAtom,
} from "../../state/modal/ModalAtom";
import { useAtom } from "jotai";

const FavoriteModal = () => {
  const [modalState, setModalState] = useAtom(favoriteModalStateAtom);

  if (!modalState.isOpen) return null;
  return (
    <div className={classes.container}>
      <div className={classes.modal} style={{ width: 343, height: 285 }}>
        <div className={classes.modalLayout}>
          <div>
            <div className={classes.butonArea}>
              <img
                src={modalState.isInterest ? favorite : unfavorite}
                alt="favorite"
              />
            </div>
            <div className={classes.message}>
              {modalState.isInterest
                ? "お気に入りマークを付けました"
                : "お気に入りマークを外しました"}
            </div>
          </div>
          <div
            className={classes.close}
            onClick={() =>
              setModalState({
                isOpen: false,
                isInterest: modalState.isInterest,
              })
            }
          >
            閉じる
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavoriteModal;
