import {
  ActionItem,
  ActionType,
  RankCategory,
  UsersAction,
  fetchUsersAction,
} from "../../api/UsersActionApi";
import classes from "./Action.module.scss";
import React, { CSSProperties, useEffect, useState } from "react";
import yellowBadge from "../../static/images/BadgeYellowEdit.svg";
import greenBadge from "../../static/images/BadgeGreenEdit.svg";
import PageTitle from "../../atoms/page-title/PageTitle";
import Header from "../../molecules/header/Header";
import badgeGrayEdit from "../../static/images/BadgeGrayEdit.svg";
import badgeGreenEdit from "../../static/images/BadgeGreenEdit.svg";
import badgeYellowEdit from "../../static/images/BadgeYellowEdit.svg";
import badgeRainbowEdit from "../../static/images/BadgeRainbowEdit.svg";
import badgeBronze from "../../static/images/BadgeBronze.svg";
import badgeSilver from "../../static/images/BadgeSilver.svg";
import badgeGold from "../../static/images/BadgeGold.svg";
import badgePlatinum from "../../static/images/BadgePlatinum.svg";
import arrowIcon from "../../static/images/arrow-right.svg";
import ActionCard, { ActionCardType } from "../../atoms/action-card/ActionCard";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchUser } from "../../api/UsersApi";
import completeImage from "../../static/images/complete.svg";
import SimpleButton from "../../atoms/simple-button/SimpleButton";
import teacherImage from "../../static/images/teacher-normal.svg";
import TeacherComment from "../../molecules/teacher-commnet/TeacherComment";
import { UsersGoal, fetchUsersGoal } from "../../api/UsersGoalApi";

type DisplayActionMap = {
  [key in ActionType]: {
    [key in RankCategory]: ActionCardType[];
  };
};

const Action = () => {
  const navigate = useNavigate();
  const { state, fetch } = fetchUsersAction();
  const fetchUserApi = fetchUser();
  const fetchUsersGoalAPI = fetchUsersGoal();
  const [displayActionMap, setDisplayActionMap] = useState<DisplayActionMap>();
  const [activeTab, setActiveTab] = useState<ActionType>("rank");
  const rankList: RankCategory[] = ["bronze", "silver", "gold"];
  const [goals, setGoals] = useState<UsersGoal[] | null>(null);
  const location = useLocation();
  const data = location?.state;

  useEffect(() => {
    const fitstTab = data?.tab ?? "goal";
    setActiveTab(fitstTab);
    callApi(fitstTab);
  }, []);

  const selectTab = (actionType: ActionType) => {
    setActiveTab(actionType);
    callApi(actionType);
  };

  const callApi = (actionType: ActionType) => {
    if (actionType === "plus" || actionType === "rank") {
      fetch({
        actionType,
      });
    } else {
      fetchUsersGoalAPI.fetch();
    }
  };

  const checkDisabled = (d: ActionItem) => {
    if (fetchUserApi.state?.data?.[0].currentRank === "bronze") {
      return d.rankCategory === "silver" || d.rankCategory === "gold";
    } else if (fetchUserApi.state?.data?.[0].currentRank === "silver") {
      return d.rankCategory === "gold";
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (state?.data == null) {
      return;
    }

    const map: any = {};
    state.data[0].actionList.forEach((d) => {
      if (!(d.actionType in map)) {
        map[d.actionType] = {};
      }

      if (!(d.rankCategory in map[d.actionType])) {
        map[d.actionType][d.rankCategory] = [];
      }

      const disabled = checkDisabled(d);

      map[d.actionType][d.rankCategory].push({
        mainLabel: d.actionCategory,
        label: d.actionSubTitle,
        status: "INITIAL",
        title: d.actionMainTitle,
        completedDate: d.completedDate,
        isInterest: d.isInterest,
        actionId: d.actionId,
        disabled,
        rank: d.rankCategory,
        badge: (() => {
          if (d.acquiredBadgeType === "green") {
            return badgeGreenEdit;
          }

          if (d.acquiredBadgeType === "yellow") {
            return badgeYellowEdit;
          }

          if (d.acquiredBadgeType === "rainbow") {
            return badgeRainbowEdit;
          }
          return badgeGrayEdit;
        })(),
        onClick: () => {
          navigate(
            `/action-detail?actionId=${d.actionId}&actionType=${d.actionType}`
          );
        },
      });
    });

    setDisplayActionMap(map);
  }, [state?.data?.[0]]);

  useEffect(() => {
    if (fetchUsersGoalAPI.state?.data == null) {
      return;
    }
    setGoals(fetchUsersGoalAPI.state?.data);
  }, [fetchUsersGoalAPI.state?.data]);

  const getClass = (actionType: ActionType) => {
    if (activeTab === actionType) {
      return `${classes.tab} ${classes.active}`;
    }

    return `${classes.tab}`;
  };

  const getIcon = (rank: RankCategory) => {
    if (rank === "bronze") {
      return badgeBronze;
    }

    if (rank === "silver") {
      return badgeSilver;
    }

    return badgeGold;
  };

  // どのくらいアクションが完了してるか
  const doneAction = (rank: RankCategory) => {
    if (
      displayActionMap == null ||
      displayActionMap[activeTab] == null ||
      displayActionMap[activeTab][rank] == null ||
      displayActionMap[activeTab][rank].length === 0
    ) {
      return { total: null, remain: null };
    }

    const lst = displayActionMap[activeTab][rank];
    const done = lst.filter((d) => d.completedDate != null).length;

    return { total: lst.length, done };
  };

  const getProgressBarStyle = (rank: RankCategory): CSSProperties => {
    const { total, done } = doneAction(rank);

    const baseProperties = {
      backgroundColor: "#66c6bb",
      height: "10rem",
      Position: "absolute",
    };

    if (total == null || done == null) {
      return {
        ...baseProperties,
        width: "0",
      };
    }

    const width = (100 / total) * done;
    return {
      ...baseProperties,
      width: `${width}%`,
    };
  };
  return (
    <div className={classes.container}>
      <Header />
      <PageTitle title="アクション" />
      <div className={classes.body}>
        <div className={classes.tabList}>
          <div
            className={getClass("goal")}
            onClick={() => {
              selectTab("goal");
            }}
          >
            ゴール
          </div>
          <div
            className={getClass("rank")}
            onClick={() => {
              selectTab("rank");
            }}
          >
            ランク
          </div>
          <div
            className={getClass("plus")}
            onClick={() => {
              selectTab("plus");
            }}
          >
            プラス
          </div>
        </div>
        
        {activeTab === "rank" ? (
        <div className={classes.badgeProgressBar}>
              <img src={badgeBronze} className={classes.badge} />
              <div className={classes.progressBar}>
                <div style={getProgressBarStyle("bronze")}></div>
              </div>
              <img src={badgeSilver} className={classes.badge} />
              <div className={classes.progressBar}>
                <div style={getProgressBarStyle("silver")}></div>
              </div>
              <img src={badgeGold} className={classes.badge} />
              <div className={classes.progressBar}>
                <div style={getProgressBarStyle("gold")}></div>
              </div>
              <img src={badgePlatinum} className={classes.badge} />
        </div>
        ):(  <></>)
        }
        {activeTab !== "goal" ? (
          <>
            <div className={classes.actionContainer}>
              {rankList.map((rank, parentIndex) => {
                if (
                  displayActionMap == null ||
                  displayActionMap[activeTab] == null ||
                  displayActionMap[activeTab][rank] == null ||
                  displayActionMap[activeTab][rank].length === 0
                ) {
                  return <React.Fragment key={parentIndex}></React.Fragment>;
                }
                const lst = displayActionMap[activeTab][rank];
                return (
                  <div
                    className={classes.rankContainer}
                    key={`${activeTab}-${rank}-"parent"-${parentIndex}`}
                  >
                    <div className={classes.rankHeader}>
                      <img className={classes.icon} src={getIcon(rank)} />
                      <div className={classes.title}>{lst[0].mainLabel}</div>
                      {activeTab === "rank" && (
                        <div className={classes.text}>
                          {lst.length -
                            lst.filter((d) => d.completedDate != null)
                              .length ===
                          0 ? (
                            <img src={completeImage} alt={completeImage} />
                          ) : (
                            <>
                              {!lst[0].disabled && (
                                <>
                                  あと
                                  {lst.length -
                                    lst.filter((d) => d.completedDate != null)
                                      .length}
                                  つ実行でランクアップ
                                </>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                    {lst.map((d, index) => {
                      return (
                        <div
                          className={classes.cardContainer}
                          key={`${activeTab}-${rank}-child-${index}`}
                        >
                          <ActionCard data={d} />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <>
            <div className={classes.buttonArea}>
              <SimpleButton
                label="＋ 夢・目標を新規作成"
                width="80%"
                color="black"
                isRadius={true}
                onClick={() =>
                  navigate("/goal-create", {
                    state: {
                      settingType: "create",
                    },
                  })
                }
              ></SimpleButton>
            </div>
            <div className={classes.goalContainer}>
              {goals == null || goals?.length === 0 ? (
                <TeacherComment
                  image={teacherImage}
                  comment={[
                    "ゴールアクションは、自分や家族の「夢」や「目標」を設定して取り組む特別なアクションです♪",
                  ]}
                ></TeacherComment>
              ) : (
                goals.map((goal, index) => (
                  <React.Fragment key={index}>
                    <div
                      className={classes.body}
                      onClick={() =>
                        navigate("/goal-create", {
                          state: {
                            settingType: "update",
                            content: goal.goalContent,
                            goalId: goal.goalId,
                          },
                        })
                      }
                    >
                      <div className={classes.title}>{goal.goalContent}</div>
                      <img className={classes.arrow} src={arrowIcon} />
                    </div>
                  </React.Fragment>
                ))
              )}
            </div>
          </>
        )}
      </div>
      <div className={classes.breadCrumb}>
        <BreadCrumb data={["みらいいコンシェル", "アクション"]} />
      </div>
    </div>
  );
};

export default Action;
