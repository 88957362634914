import React from "react";
import startChar from "../../../static/images/TopButtonChar.png";
import styles from "./DiagnosisTopContent.module.scss";
import { useNavigate } from "react-router-dom";

const DiagnosisTopContent = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.imageWrapper}>
        <img
          src={startChar}
          alt="startChar"
          className={styles.buttonCharImage}
        />
        {/* TODO：ボタンのatoms化 */}
        <button className={styles.button} onClick={() => navigate("/question")}>
          診断スタート
        </button>
      </div>
      {/* 11月初旬では実装しない。ローカルストレージへの診断結果に加えて、教育費用数字も保存する必要あり
      <div className={styles.linkContainer}>
        <p>過去の診断結果はこちら</p>
      </div>
      */}
    </>
  );
};

export default DiagnosisTopContent;
