import React, { useEffect, useState } from "react";
import classes from "./Header.module.scss";
import icon from "../../static/images/header_icon.png";
import { useAtom } from "jotai";
import { sideMenuAtom } from "../../state/side-menu/SideMenuAtom";
import Avatar from "../../atoms/avatar/Avatar";
import { User, fetchUser } from "../../api/UsersApi";
import { useNavigate } from "react-router-dom";
import { logout } from "../../api/LogoutApi";

type HeaderProps = {
  hideMenu?: true;
  nonCalledUser?: boolean;
};

const Header = (props: HeaderProps) => {
  const [state, setState] = useAtom(sideMenuAtom);
  const [userName, setUserName] = useState<string | null>(null);
  const fetchUserApi = fetchUser();
  const logoutApi = logout();

  const navigate = useNavigate();

  useEffect(() => {
    if (!props.nonCalledUser) {
      fetchUserApi.fetch();
    }
  }, []);

  useEffect(() => {

    if (fetchUserApi.state?.data == null) {
      return;
    }

    const user = fetchUserApi.state?.data[0];

    //初期ログイン画面遷移せずにヘッダーが描画された場合
    if (user?.isFirstLogin) {
      navigate("/first-setting");
    }

    const name = user?.familyProfile.filter(
      (f) => f.familyRelationship === "myself"
    )[0]?.familyName;

    if (name == null) {
      return;
    }
    setUserName(name);
  }, [fetchUserApi.state?.data]);

  useEffect(() => {
    if (logoutApi.state?.status === "success") {
      setUserName(null);
    }
  }, [logoutApi.state?.status]);

  const openMenu = () => {
    if (userName == null) {
      return;
    }
    setState({
      isShow: true,
      userName,
    });
  };

  return (
    <div className={classes.container}>
      <img className={classes.img} src={icon} alt={icon} />
      {props.hideMenu == null && userName != null && (
        <Avatar userName={userName} onClick={openMenu}></Avatar>
      )}
    </div>
  );
};

export default Header;
