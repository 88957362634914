import { atom, useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { State } from "../../api/State";
import { AxiosError } from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./ErrorHandler.module.scss";
import SimpleButton from "../../atoms/simple-button/SimpleButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

export const errorAtom = atom<any>(null);

const ErrorHandler = () => {
  const location = useLocation();
  const [state] = useAtom(errorAtom);
  const navigate = useNavigate();
  const [messages, setMessages] = useState<string[] | null>(null);

  const systemErrorMessages = [
    "予期せぬエラーが発生しました。再度処理を実行してください。",
  ];

  useEffect(() => {
    if (state == null) {
      return;
    }
    if (state instanceof AxiosError) {
      if (state.response?.status === 401 && location.pathname !== "/login") {
        setTimeout(() => {
          navigate("/login");
        });
        setMessages(["再ログインしてください。"]);
      } else {
        const messages = state.response?.data?.error;
        setMessages(
          messages == null || messages.length === 0
            ? systemErrorMessages
            : messages
        );
      }
    } else if (
      Array.isArray(state) &&
      state.length > 0 &&
      typeof state[0] === "string"
    ) {
      setMessages(state);
    } else {
      setMessages(systemErrorMessages);
    }
  }, [state]);

  const close = () => {
    setMessages(null);
  };

  return (
    <>
      {messages != null && (
        <div className={classes.container}>
          <div className={classes.inner}>
            <div className={classes.messageArea}>
              {messages.map((m, index) => (
                <div
                  className={classes.message}
                  key={`error-handler-message-${index}`}
                >
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faExclamationCircle}
                  />
                  <span>{m}</span>
                </div>
              ))}
            </div>
            <div className={classes.button}>
              <SimpleButton
                label="閉じる"
                onClick={close}
                width="100%"
                color="white"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ErrorHandler;
