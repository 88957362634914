import React, { ChangeEvent } from "react";
import classes from "./SelectBox.module.scss";

type SelectBoxProps = {
  id: string; // 一意に特定するために必須
  label?: string;
  title: SelectBoxTitle;
  defaultValue: string;
  option: SelectBoxOption[];
  onChange: (args: SelectBoxOption) => void;
};

export type SelectBoxOption = {
  name: string;
  value: string;
};

export type SelectBoxTitle = {
  name?: string;
  value: string;
};

const SelectBox = (props: SelectBoxProps) => {
  const onChange = (e: ChangeEvent<HTMLSelectElement>, name: string) => {
    props.onChange({
      name,
      value: e.target.value,
    });
  };
  return (
    <div className={classes.container}>
      <label className={classes.label}>{props.label}</label>
      <div>
        <div className={classes.selectAreaContainer}>
          <div>{props.title?.name}</div>
          <select
            defaultValue={props.defaultValue}
            className={classes.select}
            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
              onChange(e, props.title?.value)
            }
          >
            <option value="none">選択してください</option>
            {props.option.map((option, index) => (
              <React.Fragment key={props.id + "-" + index}>
                <option value={option.value}>{option.name}</option>
              </React.Fragment>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default SelectBox;
