import { atom, useAtom } from "jotai";
import { State } from "./State";
import axios from "axios";
import { fetchBase, postBase } from "./BaseApi";

export type ChartSetType = {
  decade: number;
  revenue: number;
  expense: number;
};

export type UsersEducationChart = {
  currentAge: number;
  chartSet: ChartSetType[];
};

const uri = "api/users/education-chart";

export const fetchUsersEducationChartAtom =
  atom<State<UsersEducationChart> | null>(null);
export const fetchUsersEducationChart = () => {
  return fetchBase<UsersEducationChart>(uri, fetchUsersEducationChartAtom);
};
