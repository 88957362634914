import { atom, useAtom } from "jotai";
import { State } from "./State";
import axios from "axios";
import { fetchBase, postBase } from "./BaseApi";

export type UsersLifeplanChart = {
  currentAge: number;
  lastTotalAsset: number;
  flowEvaluation: boolean;
  stockEvaluation: boolean;
  chartSet: {
    decade: number;
    totalAsset: number;
    annualSaving: number;
    totalCash: number;
    annualCashflow: number;
  }[];
};

const uri = "api/users/lifeplan-chart";

export const fetchUsersLifeplanChartAtom =
  atom<State<UsersLifeplanChart> | null>(null);
export const fetchUsersLifeplanChart = () => {
  return fetchBase<UsersLifeplanChart>(uri, fetchUsersLifeplanChartAtom);
};
