import { atom, useAtom } from "jotai";
import { State } from "./State";
import axios from "axios";
import { fetchBase, postBase } from "./BaseApi";

export type UsersEducationFee = {
  totalTuitionFee: number;
  totalTuitionFeeYearlyBased: number;
  totalTuitionFeeMonthlyBased: number;
};

const uri = "api/users/education-fee";

export const fetchUsersEducationFeeAtom = atom<State<UsersEducationFee> | null>(
  null
);
export const fetchUsersEducationFee = () => {
  return fetchBase<UsersEducationFee>(uri, fetchUsersEducationFeeAtom);
};
