// Top.jsx
import React from "react";
import logoImage from "../../../static/images/titleLogo.png";
import img1 from "../../../static/images/topChar1.png";
import img2 from "../../../static/images/topChar2.png";
import img3 from "../../../static/images/topChar3.png";
import img4 from "../../../static/images/topChar4.png";
import img5 from "../../../static/images/topChar5.png";
import img6 from "../../../static/images/topChar6.png";
import img7 from "../../../static/images/topChar7.png";
import img8 from "../../../static/images/topChar8.png";
import img9 from "../../../static/images/topChar9.png";
import styles from "./DiagnosisTopHeader.module.scss";
import diagnosisLogo from "../../../static/images/diagnosisLogo.svg";

const DiagnosisTopHeader = () => {
  return (
    <div className="header">
      <div className={styles.messageBar}>
        お金との関わり方 × 子どもとの関わり方
      </div>
      <div className={styles.diagnosisLogo}>
        <img src={diagnosisLogo} alt="diagnosisLogo" />
      </div>
      {/* TODO：画像を囲んでいる四角が表現できていない部分 */}
      <div className={styles.imageContainer}>
        <div className={styles.imagesWrapper}>
          <div className={styles.imageBlock}>
            <img src={img4} alt="Image 4" className={styles.image} />
          </div>
        </div>
        <div className={styles.imagesWrapper}>
          <div className={styles.imageBlock}>
            <img src={img6} alt="Image 6" className={styles.image} />
          </div>
        </div>
        <div className={styles.imagesWrapper}>
          <div className={styles.imageBlock}>
            <img src={img9} alt="Image 9" className={styles.image} />
          </div>
        </div>
        <div className={styles.imagesWrapper}>
          <div className={styles.imageBlock}>
            <img src={img1} alt="Image 1" className={styles.image} />
          </div>
        </div>
        <div className={styles.imagesWrapper}>
          <div className={styles.imageBlock}>
            <img src={img5} alt="Image 5" className={styles.image} />
          </div>
        </div>
        <div className={styles.imagesWrapper}>
          <div className={styles.imageBlock}>
            <img src={img3} alt="Image 3" className={styles.image} />
          </div>
        </div>
        <div className={styles.imagesWrapper}>
          <div className={styles.imageBlock}>
            <img src={img8} alt="image 8" className={styles.image} />
          </div>
        </div>
        <div className={styles.imagesWrapper}>
          <div className={styles.imageBlock}>
            <img src={img2} alt="Image 2" className={styles.image} />
          </div>
        </div>
        <div className={styles.imagesWrapper}>
          <div className={styles.imageBlock}>
            <img src={img7} alt="Image 7" className={styles.image} />
          </div>
        </div>
      </div>
      <div className={styles.subMessage}>対象：未就学児～小学生の保護者</div>
    </div>
  );
};

export default DiagnosisTopHeader;
