import { atom } from "jotai";

// モーダルの管理を考える
export const modalStateAtom = atom({
  isOpen: false,
}); // モーダルが閉じている状態で初期化

export const favoriteModalStateAtom = atom({
  isOpen: false,
  isInterest: false,
});
