import { atom } from "jotai";
import { fetchBase, postBase } from "./BaseApi";
import { State } from "./State";

export type ActionType = "rank" | "goal" | "plus";
export type RankCategory = "bronze" | "silver" | "gold" | "platinum";
export type AcquiredBadgeType = string;

export type ActionItem = {
  actionId: number;
  actionType: ActionType;
  rankCategory: RankCategory;
  actionCategory: string;
  actionSubTitle: string;
  actionMainTitle: string;
  completedDate: string;
  acquiredBadgeType: AcquiredBadgeType;
  isInterest: boolean;
};
export type UsersAction = {
  userId: string;
  actionList: ActionItem[];
};

export type PostUsersAction = {
  acquiredBadgeType: AcquiredBadgeType;
  actionId: number;
  badgeExplanation: string;
  badgeImageData: string;
  badgeTitle: string;
  isAcquiredBadgeType: boolean;
  rankTitle: string;
  rankCategory: RankCategory;
  rankExplanation: string[];
  rankImageData: string;
};

type QuizParams = {
  actionId: number;
  quizList: {
    quizNumber: number;
    isCorrect: boolean;
  }[];
};

type InputParams = {
  actionId: number;
  inputList: {
    itemId: string;
    itemValue: string | number | null;
    mandatory: "required" | "optional";
  }[];
};

type fetchUserActionsParams = {
  actionType: string;
};

const uri = "api/users/action";
export const fetchUsersActionAtom = atom<State<UsersAction> | null>(null);
export const fetchUsersAction = () => {
  return fetchBase<UsersAction, fetchUserActionsParams>(
    uri,
    fetchUsersActionAtom
  );
};

export const postUsersActionAtom = atom<State<PostUsersAction> | null>(null);
export const postUsersAction = () => {
  return postBase<PostUsersAction, QuizParams | InputParams>(
    uri,
    postUsersActionAtom
  );
};
