import { atom, useAtom } from "jotai";
import { State } from "./State";
import axios from "axios";
import { fetchBase, postBase } from "./BaseApi";
import { ChartSetType } from "./UsersEducationChartApi";

export type UsersLifeplanAchievement = {
  userId: string;
  lifeplanAdvice: string;
};

const uri = "api/users/lifeplan-achievement";

export const fetchUsersLifeplanAchievementAtom =
  atom<State<UsersLifeplanAchievement> | null>(null);
export const fetchUsersLifeplanAchievement = () => {
  return fetchBase<UsersLifeplanAchievement>(
    uri,
    fetchUsersLifeplanAchievementAtom
  );
};
