import { atom, useAtom } from "jotai";

type MenuState = {
  isShow: boolean;
  userName: string;
};

export const sideMenuAtom = atom<MenuState>({
  isShow: false,
  userName: "",
});
