import { atom } from "jotai";
import { State } from "./State";
import { postBase } from "./BaseApi";

export type BookmarkParam = {
  actionId: number;
  isInterest: string | boolean;
};

const url = "api/users/bookmark";

const postUsersBookmarkAtom = atom<State<null> | null>(null);
export const postBookmark = () => {
  return postBase<null, BookmarkParam>(url, postUsersBookmarkAtom);
};
