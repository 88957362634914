export type SchoolType = {
  name: string;
  value: string;
};

export const childSchools: SchoolType[] = [
  {
    name: "幼稚園",
    value: "kindergarden",
  },
  {
    name: "小学校",
    value: "primarySchool",
  },
  {
    name: "中学校",
    value: "juniorSchool",
  },
  {
    name: "高校",
    value: "highSchool",
  },
  {
    name: "大学",
    value: "university",
  },
];

export const grade = [
  {
    name: "未就学児",
    value: "preschool",
  },
  {
    name: "幼稚園 年少",
    value: "kinder1",
  },
  {
    name: "幼稚園 年中",
    value: "kinder2",
  },
  {
    name: "幼稚園 年長",
    value: "kinder3",
  },
  {
    name: "小学校１年生",
    value: "primary1",
  },
  {
    name: "小学校２年生",
    value: "primary2",
  },
  {
    name: "小学校３年生",
    value: "primary3",
  },
  {
    name: "小学校４年生",
    value: "primary4",
  },
  {
    name: "小学校５年生",
    value: "primary5",
  },
  {
    name: "小学校６年生",
    value: "primary6",
  },
  {
    name: "中学校１年生",
    value: "junior1",
  },
  {
    name: "中学校２年生",
    value: "junior2",
  },
  {
    name: "中学校３年生",
    value: "junior3",
  },
  {
    name: "高校１年生",
    value: "high1",
  },
  {
    name: "高校２年生",
    value: "high2",
  },
  {
    name: "高校３年生",
    value: "high3",
  },
  {
    name: "大学１年生",
    value: "uni1",
  },
  {
    name: "大学２年生",
    value: "uni2",
  },
  {
    name: "大学３年生",
    value: "uni3",
  },
  {
    name: "大学４年生",
    value: "uni4",
  },
];

export const relationships = [
  {
    display: "母親",
    value: "mother",
  },
  {
    display: "父親",
    value: "father",
  },
  {
    display: "親族ほか",
    value: "other",
  },
];

export const gender = [
  {
    display: "女",
    value: "female",
  },
  {
    display: "男",
    value: "male",
  },
  {
    display: "無回答",
    value: "none",
  },
];

export const schoolType = [
  {
    name: "国公立",
    value: "public",
  },
  {
    name: "私立",
    value: "private",
  },
];

export const universityType = [
  {
    name: "国立／4年制",
    value: "public",
  },
  {
    name: "私立文系",
    value: "private_liberalart",
  },
  {
    name: "私立理系",
    value: "private_science",
  },
  {
    name: "私立医学部",
    value: "private_doctor",
  },
];

export const isChildrenSection = [
  {
    no: "1",
    title: "あなたのこと",
  },
  {
    no: "2",
    title: "パートナー・配偶者のこと",
  },
  {
    no: "3",
    title: "子どものこと",
  },
  {
    no: "4",
    title: "興味・関心",
  },
];

export const isNotChildrenSection = [
  {
    no: "1",
    title: "あなたのこと",
  },
  {
    no: "2",
    title: "パートナー・配偶者のこと",
  },
  {
    no: "3",
    title: "興味・関心",
  },
];

export const userTopic = [
  {
    id: "1",
    name: "節約",
    isSelected: false,
    value: "0",
  },
  {
    id: "2",
    name: "貯金",
    isSelected: false,
    value: "0",
  },
  {
    id: "3",
    name: "NISA",
    isSelected: false,
    value: "0",
  },
  {
    id: "4",
    name: "iDeCo",
    isSelected: false,
    value: "0",
  },
  {
    id: "5",
    name: "資産運用（株式）",
    isSelected: false,
    value: "0",
  },
  {
    id: "6",
    name: "確定拠出年金（4false1K）",
    isSelected: false,
    value: "0",
  },
  {
    id: "7",
    name: "ねんきん定期便",
    isSelected: false,
    value: "0",
  },
  {
    id: "8",
    name: "医療保険・介護保険",
    isSelected: false,
    value: "0",
  },
  {
    id: "9",
    name: "損害保険",
    isSelected: false,
    value: "0",
  },
  {
    id: "10",
    name: "生命保険",
    isSelected: false,
    value: "0",
  },
  {
    id: "11",
    name: "相続・贈与",
    isSelected: false,
    value: "0",
  },
  {
    id: "12",
    name: "ライフプランニング",
    isSelected: false,
    value: "0",
  },
];

export const childItem = [
  {
    id: "child1",
    name: "少し興味がある",
    value: "1",
  },
  {
    id: "child2",
    name: "興味がある",
    value: "2",
  },
  {
    id: "child3",
    name: "大変興味がある",
    value: "3",
  },
];
