import { atom, useAtom } from "jotai";
import React, { useEffect } from "react";
import classes from "./Loading.module.scss";
import useDocumentLoadCompleted from "../../common/hooks/DocumentLoadComplete";

const showLoadingAtom = atom<boolean>(false);

export const useLoading = () => {
  const [, setLoading] = useAtom(showLoadingAtom);
  const isLoaded = useDocumentLoadCompleted();
  useEffect(() => {
    if (isLoaded) {
      setLoading(false);
    }
  }, [isLoaded]);
  return {
    show: () => setLoading(true),
    stop: () => setLoading(false),
  };
};

const Loading = () => {
  const [loadingState] = useAtom(showLoadingAtom);
  return (
    <>
      {loadingState && (
        <div className={classes.loadingContainer}>
          <div className={classes.inner}></div>
        </div>
      )}
    </>
  );
};

export default Loading;
