import React from "react";
import classes from "./PageTitle.module.scss";
import { RankCategory } from "../../api/UsersActionApi";
import { rankMapper } from "../../common/mapepr/rank.mapper";

type Props = {
  title: string;
  rank?: RankCategory;
};
const PageTitle = (props: Props) => {
  const getRankClass = (rank: RankCategory) => {
    if (rank === "bronze") {
      return classes.bronze;
    }

    if (rank === "silver") {
      return classes.silver;
    }

    if (rank === "gold") {
      return classes.gold;
    }

    if (rank === "platinum") {
      return classes.platinum;
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.title}>{props.title}</div>
      {props.rank != null && (
        <div className={`${classes.rank} ${getRankClass(props.rank)}`}>
          {rankMapper[props.rank]}
        </div>
      )}
    </div>
  );
};

export default PageTitle;
