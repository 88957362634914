import React, { ChangeEvent, Fragment, useState } from "react";
import classes from "./RadioInCheckBox.module.scss";

type RadioInCheckBoxProps = {
  label: string;
  subLabel?: string;
  checkItem: RadioInCheckBoxItem[];
  childItem: RadioItem[];
  onChange: (args: RadioInCheckBoxItem) => void;
};

export type RadioInCheckBoxItem = {
  id: string;
  name: string;
  isSelected: boolean;
  value: string;
};

export type RadioItem = {
  id: string;
  name: string;
  value: string;
};

export type SelectState = {
  isSelected: boolean;
  value: string;
};

// チェックボックスとラジオボタン用のatomを組み合わせて作成したい
const RadioInCheckBox = (props: RadioInCheckBoxProps) => {
  const [selectedValue, setSelectedValue] = useState<SelectState[]>([
    ...Array(props.checkItem.length).fill({
      isSelected: false,
      value: "1",
    }),
  ]);
  const onChange = (id: string, value: string, index: number) => {
    const newValue = [...selectedValue];
    newValue[index] = {
      isSelected: newValue[index].isSelected,
      value,
    };
    setSelectedValue(newValue);
    const target = props.checkItem.filter((i) => i.id === id)[0];
    props.onChange({
      id,
      name: target.name,
      isSelected: target.isSelected,
      value,
    });
  };

  const changeCheckStatus = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newValue = [...selectedValue];
    newValue[index] = {
      isSelected: !newValue[index].isSelected,
      value: newValue[index].value,
    };
    setSelectedValue(newValue);
    const target = props.checkItem.filter((i) => i.id === e.target.id)[0];
    props.onChange({
      id: e.target.id,
      name: target.name,
      isSelected: !target.isSelected,
      // isSelectedをfalseに変換するならvalueを0にする
      value: target.isSelected ? "0" : "1",
    });
  };

  // ラジオボタンの制御はvalueが一致しているのかを見る
  const checkeChildStyle = (item: RadioItem, index: number) => {
    return item.value === selectedValue[index].value
      ? { backgroundColor: "#E2F9EF" }
      : {};
  };

  // チェックボックスの制御は選択フラグがtrueかを見る
  const checkParentStyle = (index: number) => {
    return selectedValue[index].isSelected
      ? { backgroundColor: "#E2F9EF" }
      : {};
  };

  return (
    <div className={classes.container}>
      <div className={classes.label}>{props.label}</div>
      <div className={classes.subLabel}>{props.subLabel}</div>
      <div className={classes.checkItemContainer}>
        {props.checkItem.map((item, index) => (
          <React.Fragment key={index}>
            <div
              className={classes.checkItem}
              style={checkParentStyle(index)}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                changeCheckStatus(e, index)
              }
            >
              <input
                className={classes.checkbox}
                type="checkbox"
                id={item.id}
                name={item.name}
              />
              <label htmlFor={item.id} className={classes.checkboxLabel}>
                {item.name}
              </label>
            </div>
            {item.isSelected && (
              <div className={classes.container}>
                <div className={classes.checkChildItemContainer}>
                  {props.childItem.map((child, childIndex) => (
                    <div
                      key={`${index}-${childIndex}`}
                      className={classes.childrenCheckItem}
                      style={checkeChildStyle(child, index)}
                      onChange={() => onChange(item.id, child.value, index)}
                    >
                      <input
                        className={classes.checkbox}
                        type="radio"
                        id={`${item.id}-${child.id}`}
                        checked={child.value === selectedValue[index].value}
                        readOnly
                      />
                      <label
                        htmlFor={`${item.id}-${child.id}`}
                        className={classes.checkboxLabel}
                      >
                        {child.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default RadioInCheckBox;
