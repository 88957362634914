import React, { CSSProperties } from "react";
import { useEffect, useState } from "react";
import classes from "./Accordion.module.scss";
import { v4 as uuidv4 } from "uuid";
import closeIcon from "../../static/images/minus.svg";
import openIcon from "../../static/images/plus.svg";
import arrowIcon from "../../static/images/arrow-right.svg";

type Element = {
  label: string;
  value?: string;
  onClick?: () => void;
};

type Props = {
  list: (Element & {
    children?: Element[];
  })[];
};

type DisplayList = (Element & {
  expand: boolean;
  class: string;
  style: CSSProperties;
  children?: Element[];
})[];

const Accordion = (props: Props) => {
  const [displayList, setDisplayList] = useState<DisplayList>();
  const uuid = uuidv4();
  useEffect(() => {
    const l: DisplayList = props.list.map((d) => {
      return {
        ...d,
        expand: false,
        class: classes.hide,
        style: {},
      };
    });

    setDisplayList(l);
  }, [props.list]);

  const show = (index: number) => {
    const elem = document.getElementById(`${uuid}-${index}`);
    const heights = Array.from(elem?.children ?? []).map(
      (e: any) => e.offsetHeight
    );
    const height = heights.reduce((a, b) => a + b, 0);

    if (displayList == null) {
      return;
    }

    displayList[index].expand = !displayList[index].expand;
    const duration = "0.2s";

    if (displayList[index].expand) {
      displayList[index].class = classes.expand;
      displayList[index].style = {
        maxHeight: `${height}px`,
        transition: `max-height ${duration} linear`,
      };
    } else {
      displayList[index].class = classes.shrink;
      displayList[index].style = {
        maxHeight: `0px`,
        transition: `max-height ${duration} linear`,
      };
    }

    setDisplayList([...displayList]);
  };

  return (
    <>
      <div className={classes.container}>
        {displayList?.map((d, parentIndex) => {
          return (
            <>
              <div
                className={classes.parentContainer}
                onClick={() => show(parentIndex)}
              >
                <div className={classes.label}>{d.label}</div>
                {d.expand ? (
                  <img
                    className={classes.icon}
                    src={closeIcon}
                    alt={closeIcon}
                  />
                ) : (
                  <img className={classes.icon} src={openIcon} alt={openIcon} />
                )}
              </div>
              <div
                className={classes.childrenContainer}
                style={d.style}
                id={`${uuid}-${parentIndex}`}
              >
                {d.children?.map((c, index) => {
                  return (
                    <div className={classes.childContainer} key={index}>
                      <div className={classes.label}>{c.label}</div>
                      <div className={classes.value}>{c.value}</div>
                      {/*
                      <img
                        className={classes.icon}
                        src={arrowIcon}
                        alt={arrowIcon}
                      />
                      */}
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default Accordion;
