import React, { useState } from "react";
import classes from "./ActionInput.module.scss";
import TextForm, { TextFormResult } from "../../atoms/text-form/TextForm";
import { ItemType } from "../../api/ActionsApi";
import { validators } from "../../common/utility/validator.util";
import SelectForm, {
  SelectFormResult,
} from "../../atoms/select-form/SelectForm";

type Props = {
  name: string;
  mandatory: "required" | "optional";
  title: string;
  caption?: string;
  value: string;
  onChange: (args: TextFormResult) => void;
  align?: "right";
  periodicity?: string;
  unit: string;
  itemType: ItemType;
  dropListValue: string;
};

const ActionInput = (props: Props) => {
  const getRequiredLabel = () => {
    if (props.mandatory === "required") {
      return "必須";
    }

    return "任意";
  };

  const getRequiredClass = () => {
    if (props.mandatory === "required") {
      return classes.required;
    }

    return classes.optional;
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={`${classes.label} ${getRequiredClass()}`}>
          {getRequiredLabel()}
        </div>
        <div className={classes.title}>{props.title}</div>
      </div>
      {props.caption != null && props.caption !== "" && (
        <div className={classes.caption}>
          {props.caption.split("\n").map((text, index) => {
            return <div key={`caption-${index}`}>{text}</div>;
          })}
        </div>
      )}
      <div className={classes.body}>
        <div className={classes.form}>
          {props.itemType === "drop_list" ? (
            <SelectForm
              name={props.name}
              value={props.value}
              list={props.dropListValue.split(",")}
              onChange={props.onChange}
              unit={props.unit}
              periodicity={props.periodicity}
            ></SelectForm>
          ) : (
            <TextForm
              name={props.name}
              value={props.value}
              color="white"
              align={props.align}
              onChange={props.onChange}
              validator={(value: string) => {
                if (props.mandatory === "required") {
                  const r = validators.required(value);

                  if (!r.status) {
                    return r;
                  }
                }
                return validators[props.itemType](value);
              }}
            />
          )}
        </div>
        {props.itemType !== "drop_list" && (
          <div className={classes.unit}>
            {props.periodicity != null
              ? `${props.unit ? `${props.unit}／` : ""}${props.periodicity}`
              : props.unit || ""}
          </div>
        )}
      </div>
    </div>
  );
};

export default ActionInput;
